import React, { useEffect } from "react";
import {
  useGetChallengesQuery,
  useUpdateChallengeMutation,
} from "../../../redux/features/api/apiSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Challenges = ({ league }) => {
  const { currentUser } = useSelector((state) => state.user);
  const id = currentUser.player.id;

  let { leagueId } = useParams();
  const { isLoading, refetch } = useGetChallengesQuery(id);

  const challenges = useSelector((state) => state.challenges.challenges);

  useEffect(() => {
    if (league) {
      refetch();
    }
  }, [leagueId]);

  const [updateDateChallenge] = useUpdateChallengeMutation();

  const handleChallengeUpdate = (status, id) => {
    updateDateChallenge({ id: id, ChallengeStatus: status })
      .then((response) => {
        if (response?.data?.error === false) {
          toast.success("Challenge Accepted successfully!");
          refetch();
        }
        if (response?.error?.status === 400) {
          toast.error(response?.error?.data.error);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };
  const handleChallengeRejected = (status, id) => {
    updateDateChallenge({ id: id, ChallengeStatus: status })
      .then((response) => {
        if (response?.data?.error === false) {
          toast.success("Challenge Rejected successfully!");
          refetch();
        }
        if (response?.error?.status === 400) {
          toast.error(response?.error?.data.error);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <div className="relative overflow-x-auto">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 shadow-md sm:rounded-lg">
          <thead className="text-xs text-gray-700 uppercase bg-gray-200">
            <tr>
              <th scope="col" className="px-6 py-3">
                Player
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {challenges.map((challenge, i) => (
              <tr key={i} className="odd:bg-gray-50 even:bg-gray-100 border-b">
                <td className="px-6 py-1">
                  {challenge.players[0].firstName}{" "}
                  {challenge.players[0].lastName}
                </td>
                <td className="px-6 py-1">{challenge.ChallengeStatus}</td>
                {challenge.ChallengeStatus === "Pending" && (
                  <td className="px-6 py-1">
                    <button
                      disabled={challenge.ChallengeStatus === "Accepted"}
                      onClick={() =>
                        handleChallengeUpdate("Accepted", challenge.id)
                      }
                      className="text-xs bg-green-300 text-white rounded-sm px-3"
                    >
                      Accept
                    </button>
                    <button
                      disabled={challenge.ChallengeStatus === "Rejected"}
                      onClick={() =>
                        handleChallengeRejected("Rejected", challenge.id)
                      }
                      className="text-xs ml-2 bg-red-300 text-white rounded-sm px-3"
                    >
                      Reject
                    </button>
                  </td>
                )}
                {challenge.ChallengeStatus === "Accepted" ? (
                  <td className="px-6 py-1">
                    <button className="text-xs bg-green-300 text-white rounded-sm px-3">
                      Accepted
                    </button>
                  </td>
                ) : challenge.ChallengeStatus === "Rejected" ? (
                  <td className="px-6 py-1">
                    <button className="text-xs bg-red-300 text-white rounded-sm px-3">
                      Rejected
                    </button>
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Challenges;
