import React, { useEffect, useState } from "react";
import {
  useGetLeagueEntriesUserQuery,
  useCreateChallengeMutation,
} from "../../../redux/features/api/apiSlice";
import loadingImg from "../../../images/loading-image.svg";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { round } from "lodash";
import { toast } from "react-toastify";

const UserLeaguePlayers = ({ league, leagueEntriesUser }) => {
  const { currentUser } = useSelector((state) => state.user);

  let { leagueId } = useParams();
  const { data, isLoading, refetch } = useGetLeagueEntriesUserQuery(leagueId, {
    skip: !league,
  });

  useEffect(() => {
    if (league) {
      refetch();
    }
  }, [leagueId]);

  let today = new Date();
  today.setDate(today.getDate() + 1);
  let tommorow = today.toLocaleDateString("en-CA");

  const [createChallengeMatch] = useCreateChallengeMutation();

  const handlecreateChallengeMatch = (challengeReceiverId) => {
    if (currentUser.player.id === challengeReceiverId) {
      toast.error("You can't challenge yourself");
      return;
    }
    createChallengeMatch({
      challengeSenderId: currentUser.player.id,
      challengeReceiverId: challengeReceiverId,
      leagueId,
    })
      .then((response) => {
        console.log("Challenge match created successfully:", response);
        if (response?.data?.error === false) {
          toast.success("Challenge match created successfully!");
        }
        if (response?.error?.status === 400) {
          toast.error(response?.error?.data.error);
        }
      })
      .catch((error) => {
        toast.error(error?.message);
      });
  };

  return (
    <>
      {!league?.customSettings?.enablePlayerList &&
      tommorow < league?.startDate ? (
        <div className="w-full">
          <div className="mt-20 text-center text-gray-500">
            Player will show 1 day before league starts
          </div>
        </div>
      ) : (
        <>
          {isLoading && (
            <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
              <div className="  relative mx-3">
                <img className="" src={loadingImg} alt="" />
              </div>
            </div>
          )}
          <div className="relative overflow-x-auto ">
            <div className="my-5 flex justify-between ">
              <div className="text-sm text-gray-500">
                {league ? data?.data?.length : leagueEntriesUser?.length}{" "}
                players found
              </div>
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
              <thead className="text-xs text-gray-700 uppercase bg-gray-200">
                <tr>
                  <th scope="col" className="px-6 py-3 w-[35%]">
                    Player
                  </th>
                  <th scope="col" className="px-6 py-3 w-[20%]">
                    Leagues
                  </th>
                  <th scope="col" className="px-6 py-3 w-[40%]">
                    Action
                  </th>
                  <th scope="col" className="px-6 py-3 w-[40%]"></th>
                </tr>
              </thead>
              {league && (
                <tbody>
                  {data &&
                    data?.data?.map((entry, i) => (
                      <tr
                        key={i}
                        className="odd:bg-gray-50  even:bg-gray-100 border-b"
                      >
                        <th
                          scope="row"
                          className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                        >
                          <div className="bg-white rounded-xl px-3">
                            <div className="flex gap-5">
                              <div className="text-[10px] text-gray-500">
                                RATING: {entry?.player?.rating}
                              </div>
                              <div className="text-[10px] text-gray-500 font-medium">
                                {entry?.player?.club && (
                                  <div className="text-[10px] text-gray-500 font-medium">
                                    {entry?.player?.club?.clubName},{" "}
                                    {entry?.player?.club?.region},{" "}
                                    {entry?.player?.club?.state}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="text-lg">
                              {entry?.player?.firstName},{" "}
                              {entry?.player?.lastName}
                            </div>
                          </div>
                        </th>
                        <td className="px-6 py-1">
                          {" "}
                          <div className="flex flex-wrap gap-1">
                            <div
                              key={i}
                              className="text-xs bg-gray-300 rounded-sm px-3"
                            >
                              {league.leagueName}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-1">
                          <div className="flex flex-wrap gap-1">
                            <div
                              key={i}
                              onClick={() => {
                                handlecreateChallengeMatch(entry.player.id);
                              }}
                              className="text-xs bg-gray-300 rounded-sm px-3"
                            >
                              Challenge
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-1"></td>
                      </tr>
                    ))}
                </tbody>
              )}
              {!league && (
                <tbody>
                  {leagueEntriesUser &&
                    leagueEntriesUser?.map((entry, i) => (
                      <tr
                        key={i}
                        className="odd:bg-gray-50  even:bg-gray-100 border-b"
                      >
                        <th
                          scope="row"
                          className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                        >
                          <div className="bg-white rounded-xl px-3">
                            <div className="flex gap-5">
                              <div className="text-[10px] text-gray-500">
                                RATING: {entry?.player?.rating}
                              </div>
                              <div className="text-[10px] text-gray-500 font-medium">
                                {entry?.player?.club && (
                                  <div className="text-[10px] text-gray-500 font-medium">
                                    {entry?.player?.club?.clubName},{" "}
                                    {entry?.player?.club?.region},{" "}
                                    {entry?.player?.club?.state}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="text-lg">
                              {entry?.player?.firstName},{" "}
                              {entry?.player?.lastName}
                            </div>
                          </div>
                        </th>
                        <td className="px-6 py-1">
                          {" "}
                          <div className="flex flex-wrap gap-1">
                            <div
                              key={i}
                              className="text-xs bg-gray-300 rounded-sm px-3"
                            >
                              {entry.league.leagueName}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-1">
                          <div className="flex flex-wrap gap-1">
                            <button
                              key={i}
                              onClick={() => {
                                handlecreateChallengeMatch(entry.player.id);
                                console.log(entry);
                              }}
                              className="text-xs bg-green-300 rounded-sm px-3 py-2"
                            >
                              Challenge
                            </button>
                          </div>
                        </td>
                        <td className="px-6 py-1"></td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default UserLeaguePlayers;
