import React from "react";
import { useDeleteTableMutation } from "../../redux/features/api/apiSlice";
import { TrashIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

export const TableCard = ({ table, index, length }) => {
  const [deleteTable] = useDeleteTableMutation();

  const handleDeleteTable = async () => {
    try {
      const res = await deleteTable(table.id);
      if (!res.data.error) {
        toast.error("Table Removed!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="relative bg-white rounded-lg border shadow flex gap-2 sm:gap-3 pt-3 pb-5 px-3 items-start justify-start w-full ">
      <div className="font-medium text-xs sm:text-sm tracking-wider">
        TABLE {table?.index}
      </div>
      <div className="py-1 px-3 bg-blue-100 rounded font-bold text-sm sm:text-base">
        {table.tableNumber}
      </div>
      {index === length - 1 && (
        <button
          onClick={handleDeleteTable}
          className=" absolute right-1 h-5 w-5 p-0.5 font-medium flex justify-center self-center items-center bg-red-400 text-white rounded-full hover:bg-red-600 "
        >
          <TrashIcon height={20} width={20} />
        </button>
      )}
    </div>
  );
};
