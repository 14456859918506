import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const leagueDrawsInitialState = {
  leagueDrawName: "",
  leagueId: "",
  leagueDrawId: "",
  startDate: "",
  leagueDrawFormat: "THF",
  startTime: "",
  scheduleMatches: false,
  defaultMatchFormat: {
    pointsPerGame: 11,
    gamesPerMatch: 5,
    deuce: true,
    playAllGames: false,
  },
  tuesdayFormatSettings: {
    idealGroupSize: 4,
    groupPreference: "large", // larger | smaller
    noAdvance: 2,
    seeding: "snake", // snake | divisions
    useExactNoOfGroups: false,
    unratedPlayersToAdvance: false,
    ladderFormat: false,
    upDown: 1,
  },
  thursdayFormatSettings: {
    idealGroupSize: 4,
    groupPreference: "small", // larger | smaller
    noAdvance: 2,
    seeding: "snake", // snake | divisions
    useExactNoOfGroups: false,
    unratedPlayersToAdvance: false,
    ladderFormat: false,
    upDown: 1,
  },
};

export const initialState = {
  leagueDraw: {},
  leagueDrawUser: {},
  displayLeagueDraws: [],
  leagueDrawPlayers: [],
  leagueDrawDates: [],
  unassigned: [],
};

export const leagueDraw = createSlice({
  name: "leagueDraw",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getLeagueDraw.matchFulfilled,
      (state, action) => {
        state.leagueDraw = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getLeagueDrawUser.matchFulfilled,
      (state, action) => {
        state.leagueDrawUser = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.displayLeagueDraw.matchFulfilled,
      (state, action) => {
        state.displayLeagueDraws = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getUnAssignedLeaguePlayers.matchFulfilled,
      (state, action) => {
        state.unassigned = action.payload.data;
      }
    );

    builder.addMatcher(
      apiSlice.endpoints.getPlayersByLeagueDrawId.matchFulfilled,
      (state, action) => {
        state.leagueDrawPlayers = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getLeagueDrawDates.matchFulfilled,
      (state, action) => {
        state.leagueDrawDates = action.payload.data;
      }
    );
  },
});

export default leagueDraw.reducer;
