import { useEffect, useState } from "react";
import { Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  useGetQueryTournamentsQuery,
  useGetQueryLeaguesQuery,
  useGetTableMatchesQuery,
  useGetTournamentTablesQuery,
  useGetLeagueTablesQuery,
} from "../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import Matches from "../../components/commons/Matches";
import { toast } from "react-toastify";
import loadingImg from "../../images/loading-image.svg";

const MatchReporter = () => {
  const [activeTab, setActiveTab] = useState("active");
  const [selected, setSelected] = useState(null);
  const [number, setNumber] = useState("");
  const [index, setIndex] = useState("");
  const [match, setMatch] = useState(false);
  const [toggleType, setToggleType] = useState("tournament"); // "tournament" or "league"

  const { isFetching: tournamentFetching, refetch } =
    useGetQueryTournamentsQuery(
      new URLSearchParams({
        activeTab: activeTab,
        status: "published",
      }).toString()
    );

  const { isFetching: leagueFetching, refetch: refetchLeagues } =
    useGetQueryLeaguesQuery(
      new URLSearchParams({
        activeTab: activeTab,
        status: "published",
      }).toString()
    );

  const { queryTournaments } = useSelector((state) => state.tournaments);
  const { queryLeagues } = useSelector((state) => state.leagues);

  const { data: tables = [], isFetching: tablesFetching } =
    useGetTournamentTablesQuery(selected ? selected?.id : null, {
      skip: !selected,
    });

  const { data: leaguetables = [], isFetching: leagueTablesFetching } =
    useGetLeagueTablesQuery(selected ? selected?.id : null, {
      skip: !selected,
    });

  const {
    isFetching: matchFetching,
    refetch: refetchMatches,

    isError,
  } = useGetTableMatchesQuery(
    {
      id: selected?.id,
      queryString: new URLSearchParams({
        index,
        number,
      }).toString(),
    },
    { skip: !match || number.length < 4 }
  );

  const { tableMatches } = useSelector((state) => state.tables);

  const handleTableMatches = () => {
    if (selected !== "" && number !== "" && index !== "") {
      setMatch(true);
      if (isError) {
        setMatch(false);
        refetchMatches();
      }
    } else {
      toast.info("field missing");
    }
  };

  const handleTabChange = () => {
    setActiveTab(activeTab === "active" ? "past" : "active");
    setSelected(null); // Clear selected tournament or league when tab changes
  };

  const handleToggleType = () => {
    setToggleType(toggleType === "tournament" ? "league" : "tournament");
    setSelected(null); // Clear selected item when toggle changes
  };

  useEffect(() => {
    if (!tournamentFetching || !leagueFetching) {
      if (toggleType === "tournament") {
        refetch();
      } else {
        refetchLeagues();
      }
    }
  }, [activeTab, toggleType]);

  useEffect(() => {
    if (isError) {
      setMatch(false);
    }
  }, []);

  return (
    <>
      {(tournamentFetching || tablesFetching || matchFetching) && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}

      {tableMatches && match && !isError && tableMatches.length > 0 ? (
        <div className="max-w-2xl mx-auto p-3">
          <div className="w-full flex justify-end">
            <button
              onClick={() => {
                setMatch(false);
                setIndex("");
                setNumber("");
              }}
              className="px-3 py-2 rounded border-2 border-gray-700"
            >
              change table
            </button>
          </div>
          <Matches matches={tableMatches} admin={true} />
        </div>
      ) : (
        <div className="grid justify-center">
          <div className="text-2xl font-semibold text-center mt-10">
            Find Your Table
          </div>
          <div className="flex justify-center mt-5 gap-3">
            <Listbox
              className="w-[400px] border rounded-lg bg-gray-100"
              value={selected}
              onChange={setSelected}
            >
              <div className="relative mt-2">
                <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                  <span className="flex items-center">
                    <span className="ml-3 block truncate text-lg">
                      {selected
                        ? toggleType === "tournament"
                          ? selected.tournamentName
                          : selected.leagueName
                        : toggleType === "tournament"
                        ? "Select Tournament"
                        : "Select League"}
                    </span>
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-gray-400"
                    />
                  </span>
                </Listbox.Button>

                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {toggleType === "tournament" ? (
                    queryTournaments?.length > 0 ? (
                      queryTournaments.map((tournament, i) => (
                        <Listbox.Option
                          key={i}
                          value={tournament}
                          className="group relative cursor-default border-b select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                        >
                          <div className="pl-3">
                            <span className="block truncate font-normal group-data-[selected]:font-semibold text-lg">
                              {tournament.tournamentName}
                            </span>
                            <div className="flex text-xs gap-1">
                              <span>{tournament.startDate}</span> -{" "}
                              <span>{tournament.endDate}</span>
                            </div>
                            <div>{tournament.location}</div>
                          </div>

                          <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white">
                            <CheckIcon aria-hidden="true" className="h-5 w-5" />
                          </span>
                        </Listbox.Option>
                      ))
                    ) : (
                      <div className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500">
                        No tournament found
                      </div>
                    )
                  ) : queryLeagues?.length > 0 ? (
                    queryLeagues.map((league, i) => (
                      <Listbox.Option
                        key={i}
                        value={league}
                        className="group relative cursor-default border-b select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                      >
                        <div className="pl-3">
                          <span className="block truncate font-normal group-data-[selected]:font-semibold text-lg">
                            {league.leagueName}
                          </span>
                          <div className="flex text-xs gap-1">
                            <span>{league.startDate}</span> -{" "}
                            <span>{league.endDate}</span>
                          </div>
                          <div>{league.location}</div>
                        </div>

                        <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white">
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      </Listbox.Option>
                    ))
                  ) : (
                    <div className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-500">
                      No league found
                    </div>
                  )}
                </Listbox.Options>
              </div>
            </Listbox>
            <div className="flex justify-center items-center gap-2">
              <input
                type="checkbox"
                checked={activeTab !== "active"}
                className="h-5 w-5"
                onChange={handleTabChange}
              />
              <span>Show Past</span>

              <input
                type="checkbox"
                checked={toggleType === "league"}
                className="h-5 w-5"
                onChange={handleToggleType}
              />
              <span>Show Leagues</span>
            </div>
          </div>
          <div className="flex gap-3 mt-5 justify-center items-center">
            {toggleType === "tournament" &&
            tables &&
            tables?.data?.length >= 1 ? (
              <select
                className="w-full border-2 rounded text-gray-700 bg-gray-100 py-2"
                onChange={(e) => {
                  const selectedIndex = e.target.selectedIndex; // Get the selected index
                  setIndex(selectedIndex); // Set the index
                }}
              >
                <option value="">Select Table</option> {/* Default option */}
                {tables?.data?.map((table, i) => (
                  <option key={i} value={table.id}>
                    Table {table.index}
                  </option>
                ))}
              </select>
            ) : toggleType === "league" &&
              leaguetables &&
              leaguetables?.data?.length >= 1 ? (
              <select
                className="w-full border-2 rounded text-gray-700 bg-gray-100 py-2"
                onChange={(e) => {
                  const selectedIndex = e.target.selectedIndex; // Get the selected index
                  setIndex(selectedIndex); // Set the index
                }}
              >
                <option value="">Select Table</option> {/* Default option */}
                {leaguetables?.data?.map((table, i) => (
                  <option key={i} value={table.id}>
                    Table {table.index}
                  </option>
                ))}
              </select>
            ) : (
              <select
                disabled
                className="w-full border-2 rounded text-gray-700 bg-gray-100 py-2 "
              >
                <option>No tables</option>
              </select>
            )}
            <div>
              <input
                placeholder="####"
                value={number}
                disabled={
                  toggleType === "tournament"
                    ? tables?.data?.length >= 1
                      ? false
                      : true
                    : leaguetables?.data?.length >= 1
                    ? false
                    : true
                }
                onChange={(e) => setNumber(e.target.value)}
                className="border-2 rounded bg-gray-100 py-2 text-center"
                minLength={4}
                maxLength={4}
                type="password"
              />
            </div>
            <button
              onClick={handleTableMatches}
              disabled={
                toggleType === "tournament"
                  ? tables?.data?.length >= 1
                    ? false
                    : true
                  : leaguetables?.data?.length >= 1
                  ? false
                  : true
              }
              className="px-5 py-2 bg-blue-700 hover:bg-blue-800 rounded border-2 shadow text-white font-medium"
            >
              Enter
            </button>
          </div>
          {isError && (
            <div className="text-center text-red-500 text-sm">
              wrong code or table has no matches
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MatchReporter;
