import {
  CalendarIcon,
  CheckBadgeIcon,
  ClockIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import React from "react";
import { Link } from "react-router-dom";

export const EventCard = ({ event, admin, view }) => {
  return (
    <>
      <Link to={event?.id}>
        <div className="relative flex flex-col items-start justify-start w-full gap-2 ">
          <div
            className={`flex ${
              view === "grid" ? "w-full" : ""
            } justify-center items-center gap-5 `}
          >
            {event?.completed ? (
              <div className="flex mt-1 items-center font-bold uppercase text-[10px] px-2 h-5 rounded-sm bg-green-500 text-white">
                COMPLETED
              </div>
            ) : (
              <div
                className={`flex mt-1 items-center font-medium uppercase text-[10px] px-2 h-5 ${
                  event?.entry ? "bg-blue-500" : "bg-gray-500"
                } text-white rounded-sm`}
              >
                {event?.entry ? "OPEN" : "CLOSED"}
              </div>
            )}
            {event?.customSettings?.usatt && (
              <div title="#USATT Verified" className="">
                <CheckBadgeIcon
                  className=" text-blue-300"
                  height={20}
                  width={20}
                />
              </div>
            )}
          </div>
          <div className={`flex ${view === "grid" ? "w-full" : ""}`}>
            <h3 className="text-xl font-bold capitalize text-center">
              {event?.eventName}
            </h3>
          </div>
          <div className="w-full flex gap-10 justify-between ">
            <div className="flex  items-start justify-between gap-2 ">
              <CalendarIcon className="w-5 h-5 text-gray-100" />
              <div className="text-sm whitespace-nowrap">
                {event?.startDate?.split("T")[0]}
              </div>
            </div>

            <div className="flex  items-center justify-start w-full gap-4">
              <div className="flex  items-start justify-between gap-2 ">
                <ClockIcon className="w-5 h-5 text-gray-100" />
                <div className="text-sm">
                  {(() => {
                    if (event?.startTime) {
                      const [hour, minute] = event.startTime.split(":");
                      const hourInt = parseInt(hour, 10);
                      const isPM = hourInt >= 12;
                      const hour12 = hourInt % 12 || 12;
                      const period = isPM ? "PM" : "AM";
                      return `${hour12}:${minute} ${period}`;
                    }
                    return null;
                  })()}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-4">
            <div className="flex gap-2">
              <UsersIcon className="w-5 h-5 text-gray-100" />
              <div className="text-sm">
                <span>
                  {event?.players}/{event?.entryRestrictions?.maxNoOfPlayers}{" "}
                  Players
                </span>
              </div>
            </div>
            {event?.specialVariations?.disability && (
              <div
                className={`flex mt-1 items-center font-medium uppercase text-[10px] px-2 h-5 bg-gray-100 text-white rounded-sm`}
              >
                HANDICAP
              </div>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};
