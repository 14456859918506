import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const leagueInitialState = {
  leagueName: "",
  membershipSystem: "none",
  startDate: new Date(),
  endDate: new Date(),
  ratingCutoffDate: new Date(),
  timezone: "est",
  email: "",
  phone: "",
  country: "",
  note: "",
  city: "",
  state: "",
  zip: "",
  address1: "",
  leagueCurrencyType: "$",
  leagueFee: 0,
  customSettings: {
    enablePlayerList: true,
    enableAutoCertifyResults: true,
    enableSelfRegistrations: true,
    enableSimpleVerification: true,
  },
  teamRatingFormula: "balanced",
  leagueManagers: [],
  players: [],
};

export const initialState = {
  leagues: [],
  queryLeagues: [],
  league: {},
};

export const leagueSlice = createSlice({
  name: "league",
  initialState: initialState,
  reducers: {
    setLeague: (state, action) => {
      state.currentLeague = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllLeagues.matchFulfilled,
      (state, action) => {
        state.leagues = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getQueryLeagues.matchFulfilled,
      (state, action) => {
        state.queryLeagues = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getLeagueById.matchFulfilled,
      (state, action) => {
        state.league = action.payload.data;
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { setLeague } = leagueSlice.actions;

export default leagueSlice.reducer;
