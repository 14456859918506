import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetEventDrawUserQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import GroupCard from "../../card/Group";
import loadingImg from "../../../images/loading-image.svg";
import FinalizedSingleElimination from "../draws/FinalizedSingleElimination";

const UserDrawsTab = ({ event }) => {
  let { eventId } = useParams();
  const [getDraw, { isFetching }] = useLazyGetEventDrawUserQuery();
  const { drawUser: draw } = useSelector((state) => state.draws);
  const [stage, setStage] = useState(
    draw?.roundRobinSettings?.activateSE || false
  );

  useEffect(() => {
    if (eventId === event?.id) {
      getDraw(eventId);
    }
  }, [event]);

  return (
    <>
      {isFetching && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}
      <div className="flex flex-col items-start justify-start w-full mt-8">
        {draw?.id && draw?.finalized ? (
          <div className="w-full">
            <div className="flex justify-between">
              <div className="flex items-start gap-2 mt-5 mb-10">
                <div className=" ">
                  <div className="flex items-center gap-3">
                    <div className="text-lg font-bold">{draw?.drawName}</div>
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <div className="text-xs font-medium text-gray-600">
                      Estemated Start: {event?.startDate?.split("T")[0]},{" "}
                    </div>
                    <div className="text-xs font-medium text-gray-600">
                      {" "}
                      {event?.startTime}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      Format:{" "}
                      {draw?.drawFormat === "RR"
                        ? "Round-Robin"
                        : "Single Elimination"}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      First to {draw?.defaultMatchFormat?.pointsPerGame} (Win by{" "}
                      {draw?.defaultMatchFormat?.deuce ? "2" : "1"} ){" "}
                      <span className="text-sm font-black">.</span> Best of{" "}
                      {draw?.defaultMatchFormat?.gamesPerMatch}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {draw?.finalized && (
              <>
                {draw?.drawFormat === "RR" ? (
                  <>
                    {draw?.finalized ? (
                      <div className="w-full">
                        <div className="w-full flex gap-3 justify-center mb-10 text-xs sm:text-sm">
                          <button
                            onClick={() => setStage(false)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              !stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Group Stage
                          </button>
                          <button
                            onClick={() => setStage(true)}
                            className={`font-medium px-3 py-3 rounded text-white ${
                              stage ? "bg-blue-800" : "bg-gray-300"
                            }`}
                          >
                            Knockout Stage
                          </button>
                        </div>
                        {!stage ? (
                          <div className="w-full grid grid-col-1 sm:grid-cols-2 gap-2 lg:gap-5">
                            {draw?.groups &&
                              draw?.groups?.length &&
                              draw.groups
                                .filter((group) => group.type === "RR")
                                .slice()
                                .sort((a, b) => a.number - b.number)
                                .map((group, i) => (
                                  <GroupCard
                                    group={group}
                                    roundRobbin={draw?.roundRobinSettings}
                                    key={i}
                                    admin={false}
                                  />
                                ))}
                          </div>
                        ) : (
                          <div>
                            <FinalizedSingleElimination
                              admin={false}
                              groups={draw?.groups?.filter(
                                (group) => group?.type === "SE"
                              )}
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}{" "}
                  </>
                ) : (
                  <div>
                    <FinalizedSingleElimination
                      groups={draw?.groups}
                      admin={false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div className="w-full text-center text-gray-500">
            No Available Draws
          </div>
        )}
      </div>
    </>
  );
};

export default UserDrawsTab;
