import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetLeagueDrawUserQuery } from "../../../redux/features/api/apiSlice";
import { useSelector } from "react-redux";
import GroupCard from "../../card/Group";
import loadingImg from "../../../images/loading-image.svg";
import FinalizedSingleElimination from "../draws/FinalizedSingleElimination";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/16/solid";
import { format } from "date-fns";
const UserLeagueDraw = ({ league }) => {
  let { leagueId } = useParams();
  const [getDraw, { isFetching }] = useLazyGetLeagueDrawUserQuery();
  let { leagueDrawUser: leagueDraw } = useSelector(
    (state) => state.leagueDraws
  );

  const { leagueDraws } = useSelector((state) => state);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);

  const [stage, setStage] = useState(
    leagueDraw?.roundRobinSettings?.activateSE || false
  );
  const handlePrevDate = () => {
    if (currentDateIndex > 0) {
      setCurrentDateIndex(currentDateIndex - 1);
    }
  };

  const handleNextDate = () => {
    if (currentDateIndex < leagueDraws.leagueDrawUser.length - 1) {
      setCurrentDateIndex(currentDateIndex + 1);
    }
  };

  leagueDraw = leagueDraw[currentDateIndex];

  useEffect(() => {
    if (leagueId === league?.id) {
      getDraw(leagueId);
    }
  }, [league]);

  return (
    <>
      {isFetching && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}

      <div className="w-full bg-gray-200 rounded mt-5">
        {leagueDraw && (
          <div className="flex justify-center items-center gap-10 p-1">
            <button
              onClick={handlePrevDate}
              // disabled={currentDateIndex === 0}
              className="w-8 h-8 p-2 bg-gray-300 rounded-md hover:bg-gray-400"
            >
              <ChevronLeftIcon />
            </button>

            <div className="text-gray-700 font-medium">
              <span>
                {format(leagueDraw.leagueDrawDate, "EEE MMM dd yyyy")}
              </span>
            </div>

            <button
              onClick={() => handleNextDate()}
              // disabled={currentDateIndex === leagueDraw.length - 1}
              className="w-8 h-8 p-2 bg-gray-300 rounded-md hover:bg-gray-400"
            >
              <ChevronRightIcon />
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col items-start justify-start w-full mt-8">
        {leagueDraw?.id ? (
          <div className="w-full">
            <div className="flex justify-between">
              <div className="flex items-start gap-2 mt-5 mb-10">
                <div className=" ">
                  <div className="flex items-center gap-3">
                    <div className="text-lg font-bold">
                      {leagueDraw?.leagueDrawName}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-2">
                    <div className="text-xs font-medium text-gray-600">
                      Estemated Start: {league?.startDate?.split("T")[0]},{" "}
                    </div>
                    <div className="text-xs font-medium text-gray-600">
                      {" "}
                      {league?.startTime}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      Format:{" "}
                      {leagueDraw?.leagueDrawFormat === "THF"
                        ? "Thursday Format"
                        : "Tuesday Format"}
                    </div>
                  </div>
                  <div className="flex items-center gap-1 mt-1">
                    <div className="text-xs font-medium text-gray-600">
                      First to {leagueDraw?.defaultMatchFormat?.pointsPerGame}{" "}
                      (Win by{" "}
                      {leagueDraw?.defaultMatchFormat?.deuce ? "2" : "1"} ){" "}
                      <span className="text-sm font-black">.</span> Best of{" "}
                      {leagueDraw?.defaultMatchFormat?.gamesPerMatch}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {leagueDraw?.id && leagueDraw?.finalized && (
              <>
                <div className="w-full">
                  <div className="w-full grid grid-col-1 sm:grid-cols-2 gap-2 lg:gap-5">
                    {leagueDraw?.groups &&
                      leagueDraw?.groups?.length &&
                      leagueDraw.groups
                        .filter((group) => group.type === "RR")
                        .slice()
                        .sort((a, b) => a.number - b.number)
                        .map((group, i) => (
                          <GroupCard
                            group={group}
                            roundRobbin={
                              leagueDraw.leagueDrawFormat === "THF"
                                ? leagueDraw?.thursdayFormatSettings
                                : leagueDraw?.tuesdayFormatSettings
                            }
                            key={i}
                            admin={false}
                          />
                        ))}
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="w-full text-center text-gray-500">
            No Available Draws
          </div>
        )}
      </div>
    </>
  );
};

export default UserLeagueDraw;
