import React from "react";
import { CalendarIcon, MapPinIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

const TournamentCard = ({ tournament, admin }) => {
  return (
    <Link
      to={admin ? `/admin/tournaments/${tournament.id}` : `/${tournament.id}`}
      className="flex flex-col items-start justify-start w-full gap-2 "
    >
      <div className="flex flex-row items-center gap-2 w-full">
        <div className="px-3 py-0.5 text-xs font-semibold text-gray-800 uppercase rounded-full min-w-max bg-white">
          {tournament?.status}
        </div>
        <div className="px-3 py-0.5 text-xs font-semibold text-gray-800 uppercase rounded-full min-w-max bg-white">
          Tournament
        </div>
      </div>
      <h3 className="text-xl font-bold capitalize">
        {tournament?.tournamentName}
      </h3>

      <div className="flex flex-row items-center justify-start w-full gap-4 text-xs sm:text-sm">
        <div className="flex flex-row items-start justify-between gap-2 ">
          <CalendarIcon className="w-5 h-5 text-white" />
          <div>{new Date(tournament.startDate).toLocaleDateString()}</div>
        </div>
        <div className="flex flex-row items-start justify-between gap-2">
          <CalendarIcon className="w-5 h-5 text-gray-100" />
          <div>{new Date(tournament.endDate).toLocaleDateString()}</div>
        </div>
      </div>
      <div className="flex flex-row items-start justify-between gap-2 text-xs sm:text-sm">
        <MapPinIcon className="w-5 h-5 text-gray-100" />
        <div>
          <span>
            {tournament.location.split(",")[0]} -{" "}
            {tournament.location.split(",")[1]}
          </span>
        </div>
      </div>
    </Link>
  );
};

export default TournamentCard;
