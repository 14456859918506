import React, { useEffect, useState } from "react";
import FormFieldError from "../../../components/commons/formFeildError..jsx";
import {
  useConnectProfileMutation,
  useCreateProfileMutation,
  useSearchByIdQuery,
  useUpdateProfileMutation,
} from "../../../redux/features/api/apiSlice.js";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/16/solid";

const PlayerSettingsTab = () => {
  const { currentUser } = useSelector((state) => state.user);
  const [createProfile] = useCreateProfileMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [connectProfile] = useConnectProfileMutation();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [show, setShow] = useState(false);
  const [connect, setConnect] = useState(currentUser?.player?.id);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");

  const { data, refetch } = useSearchByIdQuery(
    { searchTerm },
    {
      skip: !isFocused || searchTerm === "",
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...currentUser?.player,
      email: currentUser?.email,
      homeClub: currentUser?.club?.clubName,
    },
  });
  const onSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      if (currentUser?.player?.id) {
        const res = await updateProfile({
          data,
          userId: currentUser.id,
        });
        console.log(res);
        if (!res.data.data.error) {
          localStorage.setItem("pin", data.pin);
          toast.success("Profile Updated Successfully");
        }
        if (res?.error?.data?.error !== undefined) {
          toast.error(res.error.data.error || "update failed!");
        }
      } else {
        const res = await createProfile({
          data,
          userId: currentUser.id,
        });
        if (!res.data.data.error) {
          localStorage.setItem("pin", data.pin);
          toast.success("Profile Created Successfully");
        }
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlayerSelection = (player) => {
    setValue("firstName", player.firstName);
    setValue("lastName", player.lastName);

    const dob = player?.dob;
    let formattedDob = "";

    if (dob?.includes("/")) {
      const [month, day, year] = dob?.split("/");
      if (month && day && year) {
        const formattedMonth = month.length === 1 ? `0${month}` : month;
        const formattedDay = day.length === 1 ? `0${day}` : day;
        formattedDob = `${year}-${formattedMonth}-${formattedDay}`;
      }
    } else {
      formattedDob = dob;
    }

    setValue("dob", formattedDob);
    setValue("sex", player.sex);
    setValue("homeClub", player.homeClub);
    setValue("phone", player.phone);
    setValue("rating", player.rating);
    setValue("email", player.email);
    setValue("pin", player.pin);
    setSearchTerm(""); // Clear the search term
    setSelectedPlayer(player);
    setIsFocused(false); // Close the dropdown
  };

  const handleConnectProfile = async () => {
    try {
      const res = await connectProfile({
        data: selectedPlayer,
        userId: currentUser.id,
      });
      console.log(res);
      if (!res.data.data.error) {
        setConnect(1);
        toast.success("Profile Updated Successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isFocused && searchTerm) {
      const debounced = setTimeout(() => {
        refetch();
      }, 300);

      return () => clearTimeout(debounced);
    }
  }, [searchTerm, isFocused, refetch]);

  useEffect(() => {
    if (data) {
      setFilteredPlayers(data.data);
    } else {
      setFilteredPlayers([]);
    }
  }, [data]);

  return (
    <>
      {connect ? (
        <div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-3 sm:p-8 space-y-4 max-w-2xl self-center mx-auto">
                <div className="flex flex-row items-center justify-end gap-3 mt-5 md:mt-0">
                  <button
                    disabled={loading}
                    type="submit"
                    className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {loading ? "loading.." : "Submit"}
                  </button>
                </div>

                <div className="border-2 border-gray-200 rounded-xl p-1 sm:p-3 grid grid-cols-2">
                  <div className="grid items-center justify-between col-span-9 md:col-span-4 grid-cols-12 gap-4 sm:pr-3 pb-10">
                    <div className=" col-span-12 h-6"></div>
                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="firstName"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        First Name
                      </label>
                      <input
                        className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="firstName"
                        {...register("firstName", {
                          required: "First Name is required",
                        })}
                      />
                      <FormFieldError errors={errors} field={"firstName"} />
                    </div>

                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="lastName"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Last Name
                      </label>
                      <input
                        id="lastName"
                        className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        {...register("lastName", {
                          required: "Last Name is required",
                        })}
                      />

                      <FormFieldError errors={errors} field={"lastName"} />
                    </div>

                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="sex"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Gender
                      </label>
                      <select
                        className="w-full col-span-8  border-2 bg-transparent py-2.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="sex"
                        {...register("sex", { required: "Gender is required" })}
                      >
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                      </select>
                      <FormFieldError errors={errors} field={"sex"} />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="dob"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        DOB
                      </label>
                      <input
                        className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="dob"
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("dob", {
                          required: " D.O.B is required",
                        })}
                      />
                      <FormFieldError
                        errors={errors?.entryRestrictions}
                        field={"dob"}
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="homeClub"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Home Club
                      </label>
                      <input
                        className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="homeClub"
                        type="text"
                        {...register("homeClub")}
                      />
                      <FormFieldError errors={errors} field={"homeClub"} />
                    </div>

                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="phone"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Phone
                      </label>
                      <input
                        className="w-full col-span-8  border-2 bg-transparent py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="phone"
                        type="tel"
                        {...register("phone")}
                      />
                      <FormFieldError errors={errors} field={"phone"} />
                    </div>

                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="email"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <input
                        disabled
                        className="w-full col-span-8  border-2 py-1.5 p-2 bg-gray-200 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        id="email"
                        type="email"
                        {...register("email")}
                      />
                      <FormFieldError errors={errors} field={"email"} />
                    </div>
                    <div className="flex flex-col items-start justify-start w-full col-span-6">
                      <label
                        htmlFor="rating"
                        className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                      >
                        Rating
                      </label>
                      <input
                        className="w-full col-span-8 bg-gray-200  border-2 py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                        disabled
                        id="rating"
                        type="number"
                        {...register("rating")}
                      />
                      <FormFieldError errors={errors} field={"rating"} />
                    </div>
                    <div className=" w-full col-span-12 grid grid-cols-3 mt-5">
                      <div className="col-span-1"></div>
                      <div className="col-span-1 ">
                        <label
                          htmlFor="pin"
                          className="block text-center col-span-4 text-sm font-medium leading-6 text-gray-900"
                        >
                          PUBLIC PIN
                        </label>
                        <div className="flex gap-1">
                          <input
                            defaultValue={localStorage.getItem("pin") || ""}
                            className="w-full bg-gray-100 text-center border-2 py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-sm sm:leading-6 "
                            placeholder="set the pin to team members to add into events"
                            id="pin"
                            minLength={4}
                            maxLength={4}
                            type={`${show ? "text" : "password"}`}
                            {...register("pin")}
                          />
                          <button
                            onClick={() => setShow(!show)}
                            type="button"
                            className=""
                          >
                            {show ? (
                              <EyeIcon height={16} width={16} />
                            ) : (
                              <EyeSlashIcon height={16} width={16} />
                            )}
                          </button>
                        </div>
                        <FormFieldError errors={errors} field={"pin"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center">
          <div>
            <div className="mt-20 text-xl font-medium text-gray-800">
              Connect Account by USATT #ID
            </div>
            <div className="mt-5  px-4 py-2 bg-white border rounded-md">
              <input
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setTimeout(() => setIsFocused(false), 100)} // Add delay before losing focus
                placeholder="Enter USATT-ID"
                type="number"
                min={0}
                className="px-1 w-full text-center bg-transparent border-none outline-none focus:outline-none"
              />
              {isFocused && (
                <div className="absolute z-10 mt-2 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
                  {filteredPlayers?.length === 0 ? (
                    <div className="p-2">No players found</div>
                  ) : (
                    filteredPlayers.map((player) => (
                      <button
                        onMouseDown={(e) => e.preventDefault()} // Prevent blur on mousedown
                        onClick={() => {
                          handlePlayerSelection(player);
                        }}
                        key={player.id}
                        type="button"
                        className="flex justify-between items-center min-w-[250px] p-1 m-1 bg-gray-100 hover:bg-gray-200"
                      >
                        <div className="p-1">
                          <div className="text-[10px]">
                            #USATT: {player.memberId} - Rating: {player.rating}
                          </div>
                          <div className="text-sm">
                            {player.firstName} {player.lastName}
                          </div>
                        </div>
                        <div className="h-4 w-4 flex justify-center items-center rounded bg-gray-100">
                          <div className="font-medium text-sm text-black">
                            +
                          </div>
                        </div>
                      </button>
                    ))
                  )}
                </div>
              )}
            </div>
            {selectedPlayer !== "" && (
              <>
                <div className="px-3 mt-12 rounded-lg col-span-8 bg-blue-600 text-white pb-3">
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={() => {
                        setSelectedPlayer("");
                        reset();
                      }}
                      className="h-5 w-5 flex justify-center items-center rounded-sm bg-gray-400 hover:bg-gray-300"
                    >
                      x
                    </button>
                  </div>
                  <div className="">
                    <div className="flex text-xs items-center gap-2">
                      <div className=" text-gray-300">
                        Rating:{" "}
                        <span className="text-white font-medium">
                          {selectedPlayer?.rating}
                        </span>
                      </div>
                      <div className=" font-medium text-gray-300">
                        MEMBER ID:{" "}
                        <span
                          className={`${
                            selectedPlayer?.memberId
                              ? "bg-white text-blue-800"
                              : "bg-yellow-500 text-white"
                          }  px-3  font-medium`}
                        >
                          {selectedPlayer?.memberId
                            ? selectedPlayer?.memberId
                            : selectedPlayer?.tempId}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center text-lg gap-2 my-2">
                      <div className=" font-medium">
                        {selectedPlayer?.firstName},
                      </div>
                      <div className=" font-medium">
                        {selectedPlayer?.lastName}
                      </div>
                    </div>
                    {(selectedPlayer?.membershipStartDate ||
                      selectedPlayer?.membershipExpiryDate) && (
                      <>
                        <div className="flex text-xs items-center gap-2">
                          <div className=" text-gray-300">
                            Membership Expiry:
                          </div>
                          <div className=" font-medium">
                            {selectedPlayer?.membershipExpiryDate}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-center items-center mt-5">
                  <button
                    onClick={handleConnectProfile}
                    className="bg-blue-600 rounded px-3 py-2 text-white hover:bg-blue-500 font-medium"
                  >
                    Connnect
                  </button>
                </div>
              </>
            )}
            <div className="flex justify-center items-center mt-20">
              <button
                onClick={() => {
                  setConnect(1);
                  reset();
                }}
                className="bg-green-600 rounded px-3 py-2 text-white hover:bg-green-500 font-medium"
              >
                Connnect Manually
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PlayerSettingsTab;
