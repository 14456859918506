export const tournamentsColumns = [
  {
    Header: "Tournament Name",
    accessor: "tournamentName",
  },
  {
    Header: "Membership System",
    accessor: "membershipSystem",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Location",
    accessor: "tournamentLocation",
  },
  {
    Header: "Tournament Fee",
    accessor: "tournamentFee",
    Cell: ({ row }) => {
      return (
        row.original.tournamentFee + "-" + row.original.tournamentCurrencyType
      ); // or any other data you want to render in the cell
    },
  },
];

// tournamentName: "",
// membershipSystem: "none",
// startDate: new Date(),
// endDate: new Date(),
// ratingCutoffDate: new Date(),
// timezone: "",
// email: "",
// phone: "",
// tournamentLocation: "",
// tournamentCurrencyType: "",
// tournamentFee: "",
// customSettings: {
//   enablePlayerList: true,
//   enableAutoCertifyResults: true,
//   enableSelfRegistrations: true,
//   enableSimpleVerification: true,
// },
// teamRatingFormula: "balanced",
// tournamentManagers: [],
