import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { leagueDrawsInitialState } from "../../../redux/features/leagueDrawSlice";
import FormFieldError from "../../../components/commons/formFeildError.";
import {
  useAddNewLeagueDrawMutation,
  useDeleteLeagueDrawMutation,
  useUpdateLeagueDrawMutation,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";

const AddLeagueDrawForm = ({
  handleCloseForm,
  handleDeleteForm,
  league,
  leagueDraw,
  finalized,
  refetch,
}) => {
  const [addNewLeagueDraw] = useAddNewLeagueDrawMutation();
  const [deleteLeagueDraw] = useDeleteLeagueDrawMutation();
  const [updateLeagueDraw] = useUpdateLeagueDrawMutation();
  leagueDrawsInitialState.leagueId = league.id;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: leagueDraw || leagueDrawsInitialState,
  });

  const leagueDrawFormat = watch("leagueDrawFormat");
  const ladderFormat = watch("thursdayFormatSettings.ladderFormat");
  const tuesdayladderFormat = watch("tuesdayFormatSettings.ladderFormat");
  const [leagueDrawId, setLeagueDrawId] = useState("");
  const [leagueDrawDate, setLeagueDrawDate] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const onSubmit = async (data) => {
    data.leagueDrawId = data.leagueDrawName;
    try {
      const { createdAt, id, updatedAt, ...cleanedData } = data;
      let res;
      debugger;
      if (!data.leagueDrawDate) {
        toast.error("Please select a date for the league draw");
        return;
      }
      if (leagueDraw?.id) {
        res = await updateLeagueDraw({ id: leagueDraw.id, data: cleanedData });
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          handleCloseForm();
          refetch();
          window.location.reload();
        }
        if (res?.error?.data?.error !== undefined) {
          toast.info(res.error.data.error);
        }
      } else {
        res = await addNewLeagueDraw(data);
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          handleCloseForm();
          window.location.reload();
        }
        if (res?.error?.data?.error !== undefined) {
          toast.info(res.error.data.error);
        }
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  const addLeagueDrawId = (id) => {
    setLeagueDrawId(id.toLowerCase());
  };

  const addLeagueDrawDate = (date) => {
    setLeagueDrawDate(date);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteLeagueDraw(leagueDraw.id);
      if (res?.data?.error === false) {
        toast.info(res.data.data);
        handleDeleteForm();
        handleCloseForm();
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
      onClick={handleCloseForm}
    >
      <div className="bg-white rounded-lg" onClick={(e) => e.stopPropagation()}>
        <form
          className="max-w-3xl mx-auto flex justify-end items-center text-xs sm:text-base"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="px-3 sm:px-10 pt-5 pb-10 rounded-lg space-y-1 border-2">
            <div className="grid items-start justify-between w-full grid-cols-12 gap-2">
              <div className="col-span-12 flex justify-end gap-3 mb-5">
                <button
                  type="button"
                  onClick={handleCloseForm}
                  className="inline-flex self-end justify-end px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-md shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex self-end justify-end px-3 py-2 text-sm font-semibold text-white border-2 border-blue-700 bg-blue-700 rounded-md shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {leagueDraw?.id ? "Update" : "Submit"}
                </button>
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="drawsFormat"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Select Draw Format
                </label>
                <select
                  disabled={finalized}
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  id="drawsFormat"
                  {...register("leagueDrawFormat", {
                    required: "Draw format is required",
                  })}
                >
                  <option value="THF">Thursday Format</option>
                  <option value="TF">Tuesday Format</option>
                </select>
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-5">
                <label
                  htmlFor="leagueDrawName"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Draw Name
                </label>
                <input
                  onInput={(e) => addLeagueDrawId(e.target.value)}
                  id="leagueDrawName"
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  {...register("leagueDrawName", {
                    required: "Draw Name is required",
                  })}
                />

                <FormFieldError errors={errors} field={"leagueDrawName"} />
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="leagueDrawId"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Draw Id
                </label>
                <input
                  value={leagueDrawId}
                  disabled={finalized}
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0  text-sm sm:leading-6 "
                  id="leagueDrawId"
                  {...register("leagueDrawId")}
                />

                <FormFieldError errors={errors} field={"leagueDrawId"} />
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="pointsPerGame"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Points Per Game
                </label>
                <select
                  disabled={finalized}
                  className="w-full col-span-8 p-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  id="defaultMatchFormat.pointsPerGame"
                  type="number"
                  {...register("defaultMatchFormat.pointsPerGame")}
                >
                  <option>11</option>
                  <option>15</option>
                  <option>21</option>
                </select>
                <FormFieldError errors={errors} field={"pointsPerGame"} />
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="gamesPerMatch"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Games Per Match
                </label>
                <select
                  disabled={finalized}
                  className="w-full col-span-8 p-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  id="defaultMatchFormat.gamesPerMatch"
                  type="number"
                  {...register("defaultMatchFormat.gamesPerMatch")}
                >
                  <option>1</option>
                  <option>3</option>
                  <option>5</option>
                </select>
                <FormFieldError errors={errors} field={"gamesPerMatch"} />
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="leagueDrawDate"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  // value={leagueDrawDate}
                  disabled={finalized}
                  onChange={(e) => addLeagueDrawDate(e.target.value)}
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0  text-sm sm:leading-6 "
                  id="leagueDrawDate"
                  // max={today}
                  {...register("leagueDrawDate")}
                />

                <FormFieldError errors={errors} field={"leagueDrawDate"} />
              </div>
              <div className="hidden sm:flex sm:col-span-4"></div>
              <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="deuce"
                  className="w-full text-xs sm:text-sm font-medium leading-6 text-gray-900 "
                >
                  <input
                    disabled={finalized}
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="defaultMatchFormat.deuce"
                    type="checkbox"
                    {...register("defaultMatchFormat.deuce")}
                  />
                  Deuce{" "}
                  <span className="text-[10px] font-medium text-gray-500">
                    {" "}
                    : MUST WIN BY 2
                  </span>
                </label>
              </div>
              <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="playAllGames"
                  className="w-full text-xs sm:text-sm font-medium leading-6 text-gray-900 "
                >
                  <input
                    disabled={finalized}
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="defaultMatchFormat.playAllGames"
                    type="checkbox"
                    {...register("defaultMatchFormat.playAllGames")}
                  />
                  Play All Games
                </label>
              </div>
              <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="scheduleMatches"
                  className="w-full text-xs sm:text-sm font-medium leading-6 text-gray-900 "
                >
                  <input
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="scheduleMatches"
                    type="checkbox"
                    {...register("scheduleMatches")}
                  />
                  Schedule Matches
                </label>
              </div>
              {leagueDrawFormat === "THF" && (
                <div className=" items-start justify-start w-full col-span-12 grid grid-cols-12 gap-2 mt-10">
                  <h2 className="text-base font-semibold uppercase col-span-12 text-blue-800">
                    {leagueDrawFormat === "THF"
                      ? "Thursday Format Settings"
                      : "Tuesday Format Settings"}
                  </h2>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="thursdayFormatSettings.idealGroupSize"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Ideal Group Size
                    </label>
                    <input
                      disabled={finalized}
                      className="w-full p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="thursdayFormatSettings.idealGroupSize"
                      type="number"
                      min={3}
                      {...register("thursdayFormatSettings.idealGroupSize", {
                        required: "idealGroupSize Per Game is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors.thursdayFormatSettings}
                      field={"idealGroupSize"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="noAdvance"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      No Advance
                    </label>
                    <input
                      className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="noAdvance"
                      min={1}
                      max={3}
                      type="number"
                      {...register("thursdayFormatSettings.noAdvance", {
                        required: "noAdvance is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors.thursdayFormatSettings}
                      field={"noAdvance"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="groupPreference"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Group Preference
                    </label>
                    <select
                      disabled={finalized}
                      className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="groupPreference"
                      {...register("thursdayFormatSettings.groupPreference", {
                        required: "groupPreference is required",
                      })}
                    >
                      <option value="small">smaller</option>
                      <option selected value="large">
                        larger
                      </option>
                    </select>
                    <FormFieldError
                      errors={errors.thursdayFormatSettings}
                      field={"leagueDrawFormat"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="seeding"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Seeding
                    </label>
                    <select
                      disabled={finalized}
                      className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="seeding"
                      {...register("thursdayFormatSettings.seeding", {
                        required: "seedingis required",
                      })}
                    >
                      <option value="snake">Snake</option>
                      <option value="division">By Divisions</option>
                    </select>
                    <FormFieldError
                      errors={errors.thursdayFormatSettings}
                      field={"seeding"}
                    />
                  </div>
                  <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                    <label
                      htmlFor="useExactNoOfGroups"
                      className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                    >
                      <input
                        disabled={finalized}
                        className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                        id="thursdayFormatSettings.useExactNoOfGroups"
                        type="checkbox"
                        {...register(
                          "thursdayFormatSettings.useExactNoOfGroups"
                        )}
                      />
                      <span className="text-[10px] font-medium text-gray-500">
                        {" "}
                        USE EXACT NUM OF GROUPS
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                    <label
                      htmlFor="unratedPlayersToAdvance"
                      className="w-full text-sm  flex items-center font-medium leading-6 text-gray-900 "
                    >
                      <input
                        disabled={finalized}
                        className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                        id="thursdayFormatSettings.unratedPlayersToAdvance"
                        type="checkbox"
                        {...register(
                          "thursdayFormatSettings.unratedPlayersToAdvance"
                        )}
                      />
                      <span className="text-[10px] font-medium text-gray-500">
                        {" "}
                        Allow Unrated Players to Advance
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-row items-start justify-between w-full col-span-4">
                    {ladderFormat && (
                      <div className="flex flex-row items-start justify-end w-full ml-5">
                        <label
                          htmlFor="upDown"
                          className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                        >
                          <input
                            disabled={finalized}
                            className="p-0.5 w-10 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                            id="thursdayFormatSettings.upDown"
                            type="number"
                            {...register("thursdayFormatSettings.upDown")}
                          />
                          <span className="text-[10px] font-medium text-gray-500">
                            {" "}
                            Up/Down
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {leagueDrawFormat === "TF" && (
                <div className=" items-start justify-start w-full col-span-12 grid grid-cols-12 gap-2 mt-10">
                  <h2 className="text-base font-semibold uppercase col-span-12 text-blue-800">
                    {leagueDrawFormat === "THF"
                      ? "Thursday Format Settings"
                      : "Tuesday Format Settings"}
                  </h2>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="tuesdayFormatSettings.idealGroupSize"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Ideal Group Size
                    </label>
                    <input
                      disabled={finalized}
                      className="w-full p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="tuesdayFormatSettings.idealGroupSize"
                      type="number"
                      min={3}
                      {...register("tuesdayFormatSettings.idealGroupSize", {
                        required: "idealGroupSize Per Game is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors.tuesdayFormatSettings}
                      field={"idealGroupSize"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="noAdvance"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      No Advance
                    </label>
                    <input
                      className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="noAdvance"
                      min={1}
                      max={3}
                      type="number"
                      {...register("tuesdayFormatSettings.noAdvance", {
                        required: "noAdvance is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors.tuesdayFormatSettings}
                      field={"noAdvance"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="groupPreference"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Group Preference
                    </label>
                    <select
                      disabled={finalized}
                      className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="groupPreference"
                      {...register("tuesdayFormatSettings.groupPreference", {
                        required: "groupPreference is required",
                      })}
                    >
                      <option value="small">smaller</option>
                      <option value="large">larger</option>
                    </select>
                    <FormFieldError
                      errors={errors.tuesdayFormatSettings}
                      field={"leagueDrawFormat"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="seeding"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Seeding
                    </label>
                    <select
                      disabled={finalized}
                      className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="seeding"
                      {...register("tuesdayFormatSettings.seeding", {
                        required: "seedingis required",
                      })}
                    >
                      <option value="snake">Snake</option>
                      <option value="division">By Divisions</option>
                    </select>
                    <FormFieldError
                      errors={errors.tuesdayFormatSettings}
                      field={"seeding"}
                    />
                  </div>
                  <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                    <label
                      htmlFor="useExactNoOfGroups"
                      className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                    >
                      <input
                        disabled={finalized}
                        className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                        id="tuesdayFormatSettings.useExactNoOfGroups"
                        type="checkbox"
                        {...register(
                          "tuesdayFormatSettings.useExactNoOfGroups"
                        )}
                      />
                      <span className="text-[10px] font-medium text-gray-500">
                        {" "}
                        USE EXACT NUM OF GROUPS
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                    <label
                      htmlFor="unratedPlayersToAdvance"
                      className="w-full text-sm  flex items-center font-medium leading-6 text-gray-900 "
                    >
                      <input
                        disabled={finalized}
                        className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                        id="tuesdayFormatSettings.unratedPlayersToAdvance"
                        type="checkbox"
                        {...register(
                          "tuesdayFormatSettings.unratedPlayersToAdvance"
                        )}
                      />
                      <span className="text-[10px] font-medium text-gray-500">
                        {" "}
                        Allow Unrated Players to Advance
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-row items-start justify-between w-full col-span-4">
                    {tuesdayladderFormat && (
                      <div className="flex flex-row items-start justify-end w-full ml-5">
                        <label
                          htmlFor="upDown"
                          className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                        >
                          <input
                            disabled={finalized}
                            className="p-0.5 w-10 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                            id="tuesdayFormatSettings.upDown"
                            type="number"
                            {...register("tuesdayFormatSettings.upDown")}
                          />
                          <span className="text-[10px] font-medium text-gray-500">
                            {" "}
                            Up/Down
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {leagueDraw?.id && (
              <div className="">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="mt-5 text-sm px-3 py-2 font-medium bg-red-600 hover:bg-red-700 border shadow rounded text-white "
                >
                  Delete Draw
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLeagueDrawForm;
