import React, { useEffect, useState } from "react";
import SlidePanel from "../../../components/commons/slidePanel";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { classNames } from "../../../utils/classNames";
import { useSelector } from "react-redux";
import {
  useGetLeagueByIdQuery,
  useGetLeagueDrawDatesQuery,
  useLazyGetLeagueEntriesQuery,
} from "../../../redux/features/api/apiSlice";
import loading from "../../../images/loading-image.svg";

import LeagueDrawTab from "../../../components/sections/tabs/LeagueDrawTab";

import AddLeagueDrawForm from "../Events/AddLeagueDrawForm";
import ManageLeaguePlayer from "../../../components/sections/mangePlayers/ManageLeaguePlayers";
import AdminLeaguePlayersTab from "../../../components/sections/tabs/AdminLeaguePlayersTab";
import LeagueMatchesTab from "../../../components/sections/tabs/LeagueMatchesTab";

import LeagueTablesTab from "../../../components/sections/tabs/LeagueTables";
import { PencilSquareIcon } from "@heroicons/react/16/solid";
import LeagueDrawPlayers from "../../../components/sections/tabs/LeagueDrawPlayers";
import AddLeagueForm from "./AddLeagueForm";

const sidePanelConfigs = {
  players: {
    title: "Manage Players",
    description: "Following Players are Eligible for the League.",
    component: (setOpen) => <ManageLeaguePlayer />,
  },
};

const LeaguePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [drawForm, setDrawForm] = useState(false);
  let { leagueId } = useParams();
  const { refetch: refectchLeague } = useGetLeagueByIdQuery(leagueId);
  const { refetch: refectchLeagueDrawDates } =
    useGetLeagueDrawDatesQuery(leagueId);
  const { league } = useSelector((state) => state.leagues || {});

  const { leagueDrawDates } = useSelector((state) => state.leagueDraws || []);
  useEffect(() => {
    localStorage.setItem(
      "leagueDrawDate",
      JSON.stringify(leagueDrawDates[0]?.leagueDrawDate)
    );
  }, [leagueId, leagueDrawDates]);
  const [fetchEntries] = useLazyGetLeagueEntriesQuery();
  const { leagueEntries } = useSelector((state) => state.leagueEntries);
  const { isFetching } = useGetLeagueByIdQuery(leagueId);

  useEffect(() => {
    if (league.id === leagueId) {
      fetchEntries(leagueId);
      refectchLeague();
      refectchLeagueDrawDates();
    }
  }, [leagueId]);

  const tabs = [
    {
      name: "draws",
      href: "#draws",
      element: () => (
        <LeagueDrawTab league={league} leagueDrawDates={leagueDrawDates} />
      ),
    },
    {
      name: "matches",
      href: "#matches",
      element: () => (
        <LeagueMatchesTab
          leagueData={league}
          league={true}
          player={false}
          admin={true}
        />
      ),
    },

    {
      name: "draw Players",
      href: "#drawPlayers",
      element: () => <LeagueDrawPlayers />,
    },
    {
      name: "players",
      href: "#players",
      element: () => (
        <AdminLeaguePlayersTab entries={leagueEntries} leagueLevel={true} />
      ),
    },

    {
      name: "tables",
      href: "#tables",
      element: () => <LeagueTablesTab />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleSidePanelOpener = () => {
    setOpen(!open);
  };

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.href);
  };

  const handleDrawForm = () => {
    setDrawForm(false);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    if (!location.hash) {
      setActiveTab(tabs[0].name);
    } else {
      const currentTab = tabs.find((tab) => tab.href === location.hash);
      if (currentTab) {
        setActiveTab(currentTab.name);
      }
    }
  }, [location.hash]);
  useEffect(() => {
    if (activeTab === "players") {
      fetchEntries(leagueId);
    }
  }, [activeTab, leagueId, fetchEntries]);

  useEffect(() => {
    refectchLeague();
    refectchLeagueDrawDates();
  }, [leagueId]);

  return (
    <>
      {isFetching && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loading} alt="" />
          </div>
        </div>
      )}
      {Object.keys(sidePanelConfigs).includes(activeTab) && (
        <SlidePanel
          config={{ open, setOpen }}
          section={sidePanelConfigs[activeTab]}
        />
      )}
      {drawForm ? (
        <AddLeagueDrawForm handleCloseForm={handleDrawForm} league={league} />
      ) : showForm ? (
        <AddLeagueForm handleCloseForm={handleCloseForm} league={league} />
      ) : (
        <>
          <div>
            <div className=" px-3 mt-5">
              <div className="flex">
                <button onClick={() => setShowForm(true)} className="mt-1">
                  <PencilSquareIcon height={20} width={20} />
                </button>
                <div className="flex items-center gap-3">
                  <div className="text-lg font-bold">{league?.leagueName}</div>
                  {league?.status === "published" ? (
                    <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-blue-600 text-white rounded-sm">
                      {league?.status}
                    </div>
                  ) : (
                    <div className="flex items-center font-medium uppercase text-[10px] px-2 h-5 bg-gray-400 text-white rounded-sm">
                      {league?.status}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-2">
                <div className="text-xs font-medium text-gray-500">
                  {league?.startDate?.split("T")[0]} -{" "}
                </div>
                <div className="text-xs font-medium text-gray-500">
                  {" "}
                  {league?.endDate?.split("T")[0]}
                </div>
              </div>
              <div className="flex items-center gap-1 mt-1">
                <div className="text-xs font-medium text-gray-500">
                  {league?.location}
                </div>
              </div>
            </div>
          </div>

          <div className="relative pb-5 border-b border-gray-200 sm:pb-0 px-3 ">
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex mt-3 md:absolute md:right-0 md:top-3 md:mt-0">
                {activeTab === "draws" && !league?.leagueDraw?.id && (
                  <button
                    onClick={() => setDrawForm(true)}
                    className="inline-flex items-center px-3 py-2 text-xs sm:text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    New Draw
                  </button>
                )}
                {activeTab === "players" && (
                  <div className="flex gap-3">
                    <button
                      onClick={handleSidePanelOpener}
                      className="inline-flex items-center px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Manage Players
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-8">
              <div className="">
                <nav className="flex -mb-px space-x-4 sm:space-x-8">
                  {tabs.map((tab) => (
                    <button
                      onClick={() => handleActiveTabChange(tab)}
                      key={tab.name}
                      className={classNames(
                        tab.name === activeTab
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs sm:text-sm font-medium capitalize"
                      )}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div>
            {tabs.map((tab, i) => {
              if (tab.name === activeTab) {
                return (
                  <div className="px-3" key={i}>
                    {tab.element()}
                  </div>
                );
              }
              return null; // Ensure a valid return for other cases
            })}
          </div>
        </>
      )}
    </>
  );
};

export default LeaguePage;
