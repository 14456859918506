import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  PencilSquareIcon,
  ArchiveBoxXMarkIcon,
} from "@heroicons/react/24/solid";
import AddPlayerForm from "../../../pages/admin/Players/AddPlayerForm";
import { useSelector } from "react-redux";
import {
  useEntryCheckinMutation,
  useEntryNoteMutation,
  useRatingMutation,
  useLeagueRemovePlayerMutation,
  useGetLeagueByIdQuery,
  useResetLeagueDrawMutation,
  useGetPlayersByLeagueDrawIdQuery,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";
import { format, eachDayOfInterval, parseISO } from "date-fns";

const handleExportPlayers = async ({ entries }) => {
  const headers = [
    "firstName",
    "lastName",
    "gender",
    "birthDate",
    "phone",
    "email",
    "address",
    "city",
    "state",
    "zipCode",
    "homeClub",
  ];

  const csvContent = [
    headers.join(","), // CSV header
    ...entries.map((entry) =>
      [
        entry.player.firstName,
        entry.player.lastName,
        entry.player.sex,
        entry.player.dob,
        entry.player.phone,
        entry.player.email,
        entry.player.address1,
        entry.player.city,
        entry.player.state,
        entry.player.zip,
        entry.player.homeClub,
      ].join(",")
    ),
  ].join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `players.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getDatesInRange = (startDate, endDate) => {
  if (!startDate || !endDate) return []; // Return an empty array if either date is missing

  return eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  });
};

const LeagueDrawPlayers = ({ entries, leagueLevel }) => {
  const { leagueId } = useParams();
  const [resetDraw] = useResetLeagueDrawMutation();
  const { draw } = useSelector((state) => state.draws);
  useGetLeagueByIdQuery(leagueId);
  const { league } = useSelector((state) => state.leagues);
  let leagueDrawId = localStorage.getItem("leagueDrawId");
  let id = JSON.parse(leagueDrawId);

  const { isFetching, refetch } = useGetPlayersByLeagueDrawIdQuery(id);

  const { events } = useSelector((state) => state.events);
  const { leagueDrawPlayers } = useSelector((state) => state.leagueDraws);

  const [removeEventPlayer] = useLeagueRemovePlayerMutation();
  const [entryCheckin] = useEntryCheckinMutation();
  const [playerForm, setPlayerForm] = useState(false);
  const [entry, setEntry] = useState("");
  const [isToggle, setIsToggle] = useState(false);
  const [exportPlayer, setExportPlayer] = useState(false);
  const [remove, setRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState("");
  const [data, setData] = useState("");
  const [notes, setNotes] = useState({}); // State to store notes for each entry
  const [rating, setRating] = useState({});
  const [debouncedNotes, setDebouncedNotes] = useState({});
  const [debouncedRating, setDebouncedRating] = useState({});
  const [entryNote] = useEntryNoteMutation();
  const [entryRating] = useRatingMutation();
  const [checkin, setCheckin] = useState(false);
  const dates = getDatesInRange(league?.startDate, league?.endDate);

  const handleToggleChange = async (data, date) => {
    try {
      const res = await entryCheckin({ data, date });
      if (!res.data.error) {
        toast.info(`Checkin Updated`);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsToggle(!isToggle);
    }
  };

  const handleRemovePlayer = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const id = leagueId;
      if (leagueLevel) {
        data.leagueLevel = true;
      } else {
        data.leagueLevel = false;
      }
      const res = await removeEventPlayer({ id, data });
      if (res?.data?.error === false) {
        if (leagueLevel) {
          await resetDraw(draw.id);
        }
        toast.info(
          `Player Removed From ${leagueLevel ? "Tournament" : "Event"}`
        );
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
      setIndex("");
      setRemove(false);
      setData("");
    }
  };

  const handleUpdateNote = async (id) => {
    try {
      const res = await entryNote({
        id,
        data: { noteText: debouncedNotes[id] },
      });
      if (res?.data?.error === false) {
        toast.success("Note updated!");
      } else if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const handleRating = async (id) => {
    try {
      const res = await entryRating({
        id,
        data: { rating: debouncedRating[id] },
      });
      if (res?.data?.error === false) {
        toast.success("Rating updated!");
      } else if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  const closePlayerForm = () => {
    setPlayerForm(false);
  };

  const openPlayerForm = (data) => {
    setEntry(data);
    setPlayerForm(true);
  };

  const exportPlayers = async () => {
    if (exportPlayer) return;
    setExportPlayer(true);
    try {
      await handleExportPlayers({ entries });
      toast.success("Players Exported");
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setExportPlayer(false);
    }
  };

  useEffect(() => {
    const timeouts = Object.keys(notes).reduce((acc, id) => {
      acc[id] = setTimeout(() => {
        setDebouncedNotes((prev) => ({
          ...prev,
          [id]: notes[id],
        }));
      }, 3000);
      return acc;
    }, {});

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [notes]);

  useEffect(() => {
    const timeouts = Object.keys(rating).reduce((acc, id) => {
      acc[id] = setTimeout(() => {
        setDebouncedRating((prev) => ({
          ...prev,
          [id]: rating[id],
        }));
      }, 3000);
      return acc;
    }, {});

    return () => {
      Object.values(timeouts).forEach(clearTimeout);
    };
  }, [rating]);

  useEffect(() => {
    refetch();
  }, [leagueDrawId]);

  // Trigger API call when debouncedNotes for an entry changes
  useEffect(() => {
    Object.keys(debouncedNotes).forEach((id) => {
      if (debouncedNotes[id]?.trim() !== "") {
        handleUpdateNote(id);
      }
    });
  }, [debouncedNotes]);

  useEffect(() => {
    Object.keys(debouncedRating).forEach((id) => {
      if (debouncedRating[id]?.trim() !== "") {
        handleRating(id);
      }
    });
  }, [debouncedRating]);

  return (
    <>
      {leagueDrawPlayers?.length > 0 ? (
        <div className="relative overflow-x-auto ">
          <div className="my-5 flex justify-between ">
            <div className="text-sm text-gray-500">
              {leagueDrawPlayers?.length} players found
            </div>
            {/* <div onClick={exportPlayers}>
              <button className="px-3 py-2 rounded bg-blue-700 hover:bg-blue-600 hover:shadow text-white font-medium text-sm">
                {exportPlayer ? "loading.." : "Export Players"}
              </button>
            </div> */}
          </div>
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 shadow-md sm:rounded-lg">
            <thead className="text-xs text-gray-700 uppercase bg-gray-200">
              <tr>
                <th scope="col" className="px-2 py-3 w-[4%]">
                  Player Name
                </th>
                <th scope="col" className="px-3 py-3 w-[7%]">
                  Draw Rating
                </th>
              </tr>
            </thead>
            <tbody>
              {leagueDrawPlayers?.map((player, i) => (
                <tr
                  key={i}
                  className="odd:bg-gray-50  even:bg-gray-100 border-b"
                >
                  <td>
                    {player?.player?.firstName} {player?.player?.lastName}
                  </td>
                  <td>{player?.rating}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="w-full flex justify-center">
          <div className="text-center mt-20 text-gray-500">
            No Draw Player Created Yet!
          </div>
        </div>
      )}
    </>
  );
};

export default LeagueDrawPlayers;
