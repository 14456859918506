import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const tableInitialState = {
  tournamentId: "",
  tableNumber: "",
};

export const initialState = {
  tables: [],
  table: {},
  tableMatches: [],
};

export const tableSlice = createSlice({
  name: "table",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getTournamentTables.matchFulfilled,
      (state, action) => {
        state.tables = action.payload.data;
      }
    );

    builder.addMatcher(
      apiSlice.endpoints.getLeagueTables.matchFulfilled,
      (state, action) => {
        state.tables = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getTableById.matchFulfilled,
      (state, action) => {
        if (action.payload && action.payload.data) {
          state.table = action.payload.data;
        } else {
          console.error(
            "Error: Payload data is undefined or malformed",
            action.payload
          );
        }
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getTableMatches.matchFulfilled,
      (state, action) => {
        state.tableMatches = action.payload.data;
      }
    );
  },
});

export default tableSlice.reducer;
