import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const initialState = {
  finances: {},
  leagueEntries: [],
  leagueEntriesUser: [],
};

export const leagueEntrySlice = createSlice({
  name: "entry",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getTournamentFinances.matchFulfilled,
      (state, action) => {
        state.finances = action.payload.data;
      }
    );
    // builder.addMatcher(
    //   apiSlice.endpoints.getEventEntries.matchFulfilled,
    //   (state, action) => {
    //     state.eventEntries = action.payload.data;
    //   }
    // );
    builder.addMatcher(
      apiSlice.endpoints.getLeagueEntries.matchFulfilled,
      (state, action) => {
        state.leagueEntries = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getLeagueEntriesUser.matchFulfilled,
      (state, action) => {
        state.leagueEntriesUser = action.payload.data;
      }
    );
  },
});

export default leagueEntrySlice.reducer;
