import React, { useState } from "react";
import { useForm } from "react-hook-form";
import FormFieldError from "../../../components/commons/formFeildError.";
import { eventInitialState } from "../../../redux/features/eventSlice.js";
import { useSelector } from "react-redux";
import {
  useAddNewEventMutation,
  useOpenCloseEventMutation,
  useUpdateEventMutation,
} from "../../../redux/features/api/apiSlice.js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddEventForm = ({ handleCloseForm, event }) => {
  const { tournamentId } = useParams();
  const [addNewEvent] = useAddNewEventMutation();
  const [openCloseEvent] = useOpenCloseEventMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [showDetails, setShowDetails] = useState(true);
  const [showDates, setShowDates] = useState(false);
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [publish, setPublish] = useState(false);
  const [showRestrictions, setShowRestrictions] = useState(false);
  const [eventId, setEventId] = useState("");
  const { tournament } = useSelector((state) => state.tournaments);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: event || eventInitialState,
  });

  const pickFormFields = (data, allowedFields) => {
    return Object.keys(data)
      .filter((key) => allowedFields.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];
        return obj;
      }, {});
  };

  const allowedFields = [
    "tournamentId",
    "eventName",
    "eventId",
    "startDate",
    "startTime",
    "eventFee",
    "sex",
    "customSettings",
    "entryRestrictions",
    "specialVariations",
  ];

  const onSubmit = async (data) => {
    if (loading) return;
    setLoading(true);
    try {
      if (event?.id) {
        const filteredData = pickFormFields(data, allowedFields);
        const res = await updateEvent({ id: event.id, data: filteredData });
        if (!res.data.erorr) {
          toast.success("Event Updated");
        }
      } else {
        let formValues = { ...data };
        if (tournamentId) {
          formValues.tournamentId = tournamentId;
          const res = await addNewEvent(formValues);
          if (!res.data.erorr) {
            toast.success("Event Created");
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      reset();
      setLoading(false);
      handleCloseForm();
    }
  };

  const addEventId = (id) => {
    setEventId(id.toLowerCase());
  };

  const handlePublish = handleSubmit(async (data) => {
    if (publish) return;
    setPublish(false);
    try {
      if (event?.id) {
        const filteredData = pickFormFields(data, allowedFields);
        filteredData.status = "published";
        const res = await updateEvent({ id: event.id, data: filteredData });
        if (!res.data.erorr) {
          toast.success("Event Published");
        }
      } else {
        let formValues = { ...data };
        if (tournamentId) {
          formValues.status = "published";
          formValues.tournamentId = tournamentId;
          const res = await addNewEvent(formValues);
          if (!res.data.erorr) {
            toast.success("Event Published");
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setPublish(false);
      reset();
      handleCloseForm();
    }
  });

  const handleDraft = handleSubmit(async (data) => {
    if (draft) return;
    setDraft(true);
    try {
      if (event?.id) {
        const filteredData = pickFormFields(data, allowedFields);
        filteredData.status = "draft";
        const res = await updateEvent({ id: event.id, data: filteredData });
        if (!res.data.erorr) {
          toast.success("Event Drafted");
        }
      } else {
        let formValues = { ...data };
        if (tournamentId) {
          formValues.status = "draft";
          formValues.tournamentId = tournamentId;
          const res = await addNewEvent(formValues);
          if (!res.data.erorr) {
            toast.success("Event Drafted");
          }
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setDraft(false);
      reset();
      handleCloseForm();
    }
  });

  const handleOpenClose = async () => {
    if (openClose) return;
    setOpenClose(true);
    try {
      if (event?.id) {
        const res = await openCloseEvent({
          id: event.id,
          data: { entry: !event.entry },
        });
        if (!res.data.error) {
          toast.success(`Event ${event.entry ? "Closed" : "Opened"}`);
        }
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setOpenClose(false);
      reset();
      handleCloseForm();
    }
  };

  const hasDetailErrors = !!errors.eventName || !!errors.eventId;

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
      onClick={handleCloseForm}
    >
      <div
        className="bg-white rounded-md  shadow-lg relative mx-3 overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <form
          className="relative max-w-3xl w-full mx-auto p-1 sm:p-6 bg-white rounded-lg shadow-lg"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className=" space-y-3 sm:space-y-8">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              <div className="text-center sm:text-start">
                <h1 className="text-sm sm:text-lg font-semibold text-blue-600 ">
                  {event?.id ? "Update Event" : "Add New Event"}
                </h1>
                <p className="text-xs sm:text-sm text-gray-600 ">
                  Please fill in the form below for event
                </p>
              </div>
              <div className="flex flex-row items-center justify-between text-xs sm:text-sm gap-3 mt-3 sm:mt-0">
                <button
                  type="button"
                  onClick={() => {
                    handleCloseForm();
                    reset();
                  }}
                  className="inline-flex items-center border-2 border-gray-400 px-3 py-2 font-semibold text-white bg-gray-400 rounded-md shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex whitespace-nowrap items-center px-4 py-2 font-semibold text-indigo-600 border-2 border-indigo-600 rounded-md shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {loading ? "loading.." : "Save"}
                </button>
                {event?.status === "published" ? (
                  <button
                    type="button"
                    onClick={handleDraft}
                    className="inline-flex items-center px-3 py-2 font-semibold text-white border-2 border-gray-500 bg-gray-500 rounded-md shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {draft ? "laoding.." : "Draft"}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={handlePublish}
                    className="inline-flex items-center px-3 py-2 font-semibold text-white border-2 border-blue-600 bg-blue-600 rounded-md shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {publish ? "loading.." : "Publish"}
                  </button>
                )}
              </div>
            </div>
            <div className="grid items-center max-w-4xl mx-auto rounded-sm">
              <button
                onClick={() => setShowDetails(!showDetails)}
                type="button"
                className={`relative w-full border-b border-white flex justify-between items-center px-10 py-4 text-sm sm:text-lg text-gray-700 hover:bg-gray-300 ${
                  showDates ? "bg-gray-300" : "bg-gray-200"
                }`}
              >
                <span>
                  Event Details{" "}
                  {hasDetailErrors && (
                    <span className="text-red-500 text-2xl font-bold">*</span>
                  )}
                </span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 ${
                    showDetails ? "" : "rotate-180"
                  } shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  viewBox="0 0 10 6"
                >
                  <path d="M9 5 5 1 1 5" />
                </svg>
              </button>
              <div
                className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
                  showDetails
                    ? "max-h-[1000px] opacity-100 p-5"
                    : "max-h-0 opacity-0 overflow-hidden"
                }`}
              >
                <div className="flex flex-col items-start justify-start w-full col-span-12 sm:col-span-6">
                  <label
                    htmlFor="eventName"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Name
                  </label>
                  <input
                    onInput={(e) => addEventId(e.target.value)}
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="eventName"
                    {...register("eventName", {
                      required: "Event name is required",
                    })}
                  />
                  <FormFieldError errors={errors} field={"eventName"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                  <label
                    htmlFor="eventId"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Id
                  </label>
                  <input
                    disabled
                    value={eventId}
                    id="eventId"
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    {...register("eventId")}
                  />

                  <FormFieldError errors={errors} field={"eventId"} />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                  <label
                    htmlFor="eventFee"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Fee
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="eventFee"
                    type="number"
                    step="0.1"
                    min={0}
                    {...register("eventFee")}
                  />
                  <FormFieldError errors={errors} field={"eventFee"} />
                </div>
                <div className="col-span-12 flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setShowDetails(false);
                      setShowDates(true);
                    }}
                    className="px-5 self-end text-white font-medium py-2 text-xs sm:text-sm bg-blue-600 rounded"
                  >
                    Next
                  </button>
                </div>
              </div>
              <button
                onClick={() => setShowDates(!showDates)}
                type="button"
                className={`relative w-full border-b border-white flex justify-between items-center px-10 py-4 text-sm sm:text-lg text-gray-700 hover:bg-gray-300 ${
                  showDates ? "bg-gray-300" : "bg-gray-200"
                }`}
              >
                <span>Event KickOff </span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 ${
                    showDates ? "" : "rotate-180"
                  } shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  viewBox="0 0 10 6"
                >
                  <path d="M9 5 5 1 1 5" />
                </svg>
              </button>
              <div
                className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
                  showDates
                    ? "max-h-[1000px] opacity-100 p-5"
                    : "max-h-0 opacity-0 overflow-hidden"
                }`}
              >
                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="startDate"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Start Date
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="startDate"
                    min={tournament?.startDate}
                    max={tournament?.endDate}
                    type="date"
                    {...register("startDate")}
                  />
                  <FormFieldError errors={errors} field={"startDate"} />
                </div>

                <div className="flex flex-col items-start justify-start w-full col-span-6">
                  <label
                    htmlFor="startTime"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Start Time
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="startTime"
                    type="time"
                    {...register("startTime")}
                  />
                  <FormFieldError errors={errors} field={"startTime"} />
                </div>
                <div className="col-span-12 flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      setShowDates(false);
                      setShowRestrictions(true);
                    }}
                    className="px-5 self-end text-white font-medium py-2 text-xs sm:text-sm bg-blue-600 rounded"
                  >
                    Next
                  </button>
                </div>
              </div>
              <button
                onClick={() => setShowRestrictions(!showRestrictions)}
                type="button"
                className={`relative w-full border-b border-white flex justify-between items-center px-10 py-4 text-sm sm:text-lg text-gray-700 hover:bg-gray-300 ${
                  showRestrictions ? "bg-gray-300" : "bg-gray-200"
                }`}
              >
                <span>Entry Restictions </span>
                <svg
                  data-accordion-icon
                  className={`w-3 h-3 ${
                    showRestrictions ? "" : "rotate-180"
                  } shrink-0`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  viewBox="0 0 10 6"
                >
                  <path d="M9 5 5 1 1 5" />
                </svg>
              </button>
              <div
                className={`grid items-center border border-gray-300 justify-between grid-cols-12 gap-4 transition-all duration-500 ease-in-out ${
                  showRestrictions
                    ? "max-h-[1000px] opacity-100 p-5"
                    : "max-h-0 opacity-0 overflow-hidden"
                }`}
              >
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                  <label
                    htmlFor="minDOB"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Max DOB
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="maxDOB"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    {...register("entryRestrictions.maxDOB")}
                  />
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"maxDOB"}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                  <label
                    htmlFor="maxDOB"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Min DOB
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="minDOB"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    {...register("entryRestrictions.minDOB")}
                  />
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"minDOB"}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                  <label
                    htmlFor="sex"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Gender
                  </label>
                  <select
                    id="sex"
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-2 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    {...register("sex")}
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                  <FormFieldError errors={errors} field={"sex"} />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                  <label
                    htmlFor="minRating"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Min Rating
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="minRating"
                    min={0}
                    type="number"
                    {...register("entryRestrictions.minRating")}
                  />
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"minRating"}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                  <label
                    htmlFor="maxRating"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Max Rating
                  </label>
                  <input
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-1.5 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    id="maxRating"
                    type="number"
                    min={0}
                    {...register("entryRestrictions.maxRating")}
                  />
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"maxRating"}
                  />
                </div>
                <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                  <label
                    htmlFor="maxNoOfPlayers"
                    className="block col-span-4 text-sm font-medium leading-6 text-gray-900"
                  >
                    Max Players
                  </label>
                  <select
                    id="maxNoOfPlayers"
                    className="w-full col-span-8  border-2 border-gray-200 bg-white py-2 p-2  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm  rounded-md sm:leading-6"
                    {...register("entryRestrictions.maxNoOfPlayers")}
                  >
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="8">8</option>
                    <option value="16">16</option>
                    <option value="32">32</option>
                    <option value="64">64</option>
                    <option value="128">128</option>
                    <option value="256">256</option>
                  </select>
                  <FormFieldError
                    errors={errors?.entryRestrictions}
                    field={"maxNoOfPlayers"}
                  />
                </div>
              </div>
            </div>
            <div className=" w-full space-y-3 text-xs sm:text-sm">
              <h2 className=" font-medium text-gray-900">Custom Settings</h2>
              <div className="flex  justify-between w-full">
                <label
                  htmlFor="enableShowResults"
                  className=" font-medium text-gray-900"
                >
                  <input
                    className="mr-2 "
                    id="enableShowResults"
                    type="checkbox"
                    {...register("customSettings.enableShowResults")}
                  />
                  Enable Show Results
                </label>
                <label htmlFor="usatt" className=" font-medium text-gray-900">
                  <input
                    className="mr-2 "
                    id="usatt"
                    type="checkbox"
                    {...register("customSettings.usatt")}
                  />
                  #USATT Event
                </label>
                <label
                  htmlFor="handicap"
                  className=" font-medium text-gray-900"
                >
                  <input
                    className="mr-2 "
                    id="handicap"
                    type="checkbox"
                    {...register("specialVariations.disability")}
                  />
                  Handicap Event
                </label>
              </div>
              {event?.id && (
                <div className="flex flex-col items-start justify-start col-span-6 gap-4">
                  <button
                    type="button"
                    onClick={handleOpenClose}
                    className={`px-3 text-sm py-2 rounded shadow ${
                      event.entry ? "bg-blue-700" : "bg-red-600"
                    } text-white font-medium`}
                  >
                    {openClose
                      ? "loading.."
                      : event.entry
                      ? "Close Event"
                      : "Open Event"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEventForm;
