import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { update } from "lodash";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    credentials: "include",
    // prepareHeaders: (headers) => {
    //   headers.set("Content-Type", "application/json");
    //   return headers;
    // },
    // responseHandler: async (response) => {
    //   const rawResponse = await response.text();
    //   console.log("Raw Response:", rawResponse); // Log raw response
    //   return response.json();
    // },
  }),
  tagTypes: [
    "new-tournament",
    "new-event",
    "new-player",
    "new-draw",
    "event-players",
    "entry",
  ],
  endpoints: (builder) => ({
    // auth builders

    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    logout: builder.mutation({
      query: (data) => ({
        url: "/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["new-user"],
    }),
    getUser: builder.query({
      query: (id) => `/auth/user/${id}`,
      providesTags: ["new-user"],
    }),
    signup: builder.mutation({
      query: (data) => ({
        url: "/auth/signup",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    createProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/profile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    connectProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/connectProfile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: "/auth/updateProfile",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-user"],
    }),

    // tournament builders
    getAllTournaments: builder.query({
      query: (queryString) => `/tournament/admin?${queryString}`,
      providesTags: ["new-tournament"],
    }),
    getQueryTournaments: builder.query({
      query: (query) => `/tournament/query?${query}`,
      providesTags: ["new-tournament"],
    }),
    getTournamentById: builder.query({
      query: (id) => `/tournament/${id}`,
      providesTags: ["new-tournament", "new-event"],
    }),
    addNewTournament: builder.mutation({
      query: (data) => ({
        url: "/tournament",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-tournament"],
    }),
    duplicateTournament: builder.mutation({
      query: (data) => ({
        url: "/tournament/duplicate",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-tournament"],
    }),
    updateTournament: builder.mutation({
      query: ({ id, data }) => ({
        url: `/tournament/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-tournament"],
    }),
    // league builders

    getAllLeagues: builder.query({
      query: (queryString) => `/league/admin?${queryString}`,
      providesTags: ["new-league"],
    }),
    getQueryLeagues: builder.query({
      query: (query) => `/league/query?${query}`,
      providesTags: ["new-league"],
    }),
    getLeagueById: builder.query({
      query: (id) => `/league/${id}`,
      providesTags: ["new-league", "new-event"],
    }),
    addNewLeague: builder.mutation({
      query: (data) => ({
        url: "/league",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-league"],
    }),
    duplicateLeague: builder.mutation({
      query: (data) => ({
        url: "/league/duplicate",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-league"],
    }),
    updateLeague: builder.mutation({
      query: ({ id, data }) => ({
        url: `/league/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-league"],
    }),

    leagueEnrollment: builder.mutation({
      query: ({ eventId, data }) => ({
        url: `/league/enrollment/${eventId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "eligible-players",
        "unassigned",
      ],
    }),
    // events builders

    getAllEvents: builder.query({
      query: () => `/event`,
      providesTags: ["new-event"],
    }),
    getEventById: builder.query({
      query: (id) => `/event/${id}`,
      providesTags: ["new-event"],
    }),
    getEventsByTournamentId: builder.query({
      query: (tournamentId) => `/event/tournament/${tournamentId}`,
      providesTags: ["new-event"],
    }),
    getQueryEventsByTournamentId: builder.query({
      query: ({ tournamentId, query }) =>
        `/event/query/${tournamentId}?status=${query}`,
      providesTags: ["new-event"],
    }),

    addNewEvent: builder.mutation({
      query: (data) => ({
        url: `/event`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-event"],
    }),
    updateEvent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/event/update/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-event"],
    }),
    openCloseEvent: builder.mutation({
      query: ({ id, data }) => ({
        url: `/event/openClose/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["new-event"],
    }),
    eventEnrollment: builder.mutation({
      query: ({ eventId, data }) => ({
        url: `/event/enrollment/${eventId}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "eligible-players",
        "unassigned",
      ],
    }),
    eventRemovePlayer: builder.mutation({
      query: ({ id, data }) => ({
        url: `/event/removePlayer/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "eligible-players",
        "unassigned",
      ],
    }),

    leagueRemovePlayer: builder.mutation({
      query: ({ id, data }) => ({
        url: `/league/removePlayer/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "league-players",
        "entry",
        "eligible-players",
        "unassigned",
      ],
    }),

    // Players builders

    getAllPlayer: builder.query({
      query: () => `/players`,
      providesTags: ["new-player"],
    }),
    getPlayerById: builder.query({
      query: (id) => `/players/${id}`,
    }),
    getPlayersByTournamentId: builder.query({
      query: (tournamentId) => `/players/tournament/${tournamentId}`,
      providesTags: ["new-player"],
    }),
    getPlayersByEventId: builder.query({
      query: (eventId) => `/event/players/${eventId}`,
      providesTags: ["event-players"],
    }),

    getPlayersByLeagueId: builder.query({
      query: (leagueId) => `/league/players/${leagueId}`,
      providesTags: ["league-players"],
    }),

    getPlayersByLeagueDrawId: builder.query({
      query: (id) => `/leagueDraw/players/${id}`,
      providesTags: ["leagueDraw-players"],
    }),
    getEventEligiblePlayers: builder.query({
      query: (queryString) => `/players/eventEligible?${queryString}`,
      providesTags: ["eligible-players"],
    }),
    searchPlayer: builder.query({
      query: ({ searchTerm, tournamentId }) =>
        `/players/searchPlayer?searchTerm=${searchTerm}&tournamentId=${tournamentId}`,
    }),
    searchById: builder.query({
      query: ({ searchTerm }) =>
        `/players/searchPlayer?searchTerm=${searchTerm}`,
    }),
    addNewPlayer: builder.mutation({
      query: (data) => ({
        url: "/players/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    addNewLeaguePlayer: builder.mutation({
      query: (data) => ({
        url: "/players/addLeaguePlayer",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    updatePlayer: builder.mutation({
      query: ({ data, id }) => ({
        url: `/players/update/${id}`,
        method: "POST",
        body: data, // Passing the correct form data
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    selfRegistration: builder.mutation({
      query: ({ data, id }) => ({
        url: `/players/selfRegistration/${id}`,
        method: "POST",
        body: data, // Passing the correct form data
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    selfRegistratioForLeague: builder.mutation({
      query: ({ data, id }) => ({
        url: `/players/selfRegistrationLeague/${id}`,
        method: "POST",
        body: data, // Passing the correct form data
      }),
      invalidatesTags: ["new-player", "entry"],
    }),
    getPlayerEntries: builder.query({
      query: (id) => `/players/entries/${id}`,
      providesTags: ["entry"],
    }),

    importPlayer: builder.mutation({
      query: (data) => ({
        url: `/players/import`,
        method: "POST",
        body: data, // Pass FormData directly
      }),
      invalidatesTags: ["new-player"],
    }),

    // tables builders
    getTableById: builder.query({
      query: (id) => `/table/${id}`,
    }),
    getTournamentTables: builder.query({
      query: (id) => `/table/tournament/${id}`,
      providesTags: ["new-table"],
    }),
    getLeagueTables: builder.query({
      query: (id) => `/table/league/${id}`,
      providesTags: ["new-table"],
    }),
    getTableMatches: builder.query({
      query: ({ id, queryString }) => `/table/match/${id}?${queryString}`,
      providesTags: ["match"],
    }),
    addNewTable: builder.mutation({
      query: ({ id, data }) => ({
        url: `/table/tournament/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-table"],
    }),
    addNewLeagueTable: builder.mutation({
      query: ({ id, data }) => ({
        url: `/table/league/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["new-table"],
    }),
    deleteTable: builder.mutation({
      query: (id) => ({
        url: `/table/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["new-table"],
    }),

    //league Draws builders
    getLeagueDraw: builder.query({
      query: ({ id, leagueDrawDate }) =>
        `/leagueDraw/league/${id}?date=${leagueDrawDate}`,
      providesTags: ["leagueDraw"],
    }),
    getLeagueDrawDates: builder.query({
      query: (id) => `/leagueDraw/leagueDates/${id}`,
      providesTags: ["leagueDraw"],
    }),
    getLeagueDrawUser: builder.query({
      query: (id) => `/leagueDraw/league/user/${id}`,
      providesTags: ["leagueDraw"],
    }),
    // getUnAssignedLeaguePlayers: builder.query({
    //   query: (id, date) => `/leagueDraw/unassigned/${id}?date=${date}`,
    //   providesTags: ["unassigned"],
    // }),
    displayLeagueDraw: builder.query({
      query: (query) => `/leagueDraw/display?${query}`,
      providesTags: ["leagueDraw"],
    }),
    addNewLeagueDraw: builder.mutation({
      query: (data) => ({
        url: "/leagueDraw/new",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["leagueDraw"],
    }),
    updateLeagueDraw: builder.mutation({
      query: ({ id, data }) => ({
        url: `/leagueDraw/update/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["leagueDraw"],
    }),
    deleteLeagueDraw: builder.mutation({
      query: (id) => ({
        url: `/leagueDraw/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["leagueDraw", "new-event"],
    }),
    resetLeagueDraw: builder.mutation({
      query: (id) => ({
        url: `/leagueDraw/reset/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["leagueDraw"],
    }),
    finalLeagueDraw: builder.mutation({
      query: ({ id, data }) => ({
        url: `/leagueDraw/final/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["leagueDraw"],
    }),
    leagueDrawSeeding: builder.mutation({
      query: ({ id, data }) => ({
        url: `/leagueDraw/seeding/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["leagueDraw"],
    }),

    deletePlayerRR: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/deletePlayer/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["deletePlayerRR"],
    }),

    activateLeagueKnockout: builder.mutation({
      query: ({ id, data }) => ({
        url: `/leagueDraw/activateKnockout/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["leagueDraw", "match"],
    }),

    // draw builders
    getEventDraw: builder.query({
      query: (id) => `/draw/event/${id}`,
      providesTags: ["draw"],
    }),
    getEventDrawUser: builder.query({
      query: (id) => `/draw/event/user/${id}`,
      providesTags: ["draw"],
    }),
    getUnAssignedPlayers: builder.query({
      query: (id) => `/draw/unassigned/${id}`,
      providesTags: ["unassigned"],
    }),
    getUnAssignedLeaguePlayers: builder.query({
      query: ({ id, date }) => `/leagueDraw/unassigned/${id}?date=${date}`,

      providesTags: ["unassigned-leaguePlayers"],
    }),
    displayDraw: builder.query({
      query: (query) => `/draw/display?${query}`,
      providesTags: ["draw"],
    }),
    addNewDraw: builder.mutation({
      query: (data) => ({
        url: "/draw/new",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    updateDraw: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/update/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    deleteDraw: builder.mutation({
      query: (id) => ({
        url: `/draw/delete/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["draw", "new-event"],
    }),
    resetDraw: builder.mutation({
      query: (id) => ({
        url: `/draw/reset/${id}`,
        method: "POST",
      }),
      invalidatesTags: ["draw"],
    }),
    finalDraw: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/final/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    drawSeeding: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/seeding/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw"],
    }),
    activateKnockout: builder.mutation({
      query: ({ id, data }) => ({
        url: `/draw/activateKnockout/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),

    //matches
    getGroupMatches: builder.query({
      query: (id) => `/match/group/${id}`,
      providesTags: ["match"],
    }),
    getPlayerMatches: builder.query({
      query: (queryString) => `/match/player?${queryString}`,
      providesTags: ["match"],
    }),
    getEventMatches: builder.query({
      query: (queryString) => `/match/event?${queryString}`,
      providesTags: ["match"],
    }),
    getMatchByTournamentId: builder.query({
      query: (queryString) => `/match/tournament?${queryString}`,
      providesTags: ["match"],
    }),
    getMatchByLeagueId: builder.query({
      query: (queryString) => `/match/league?${queryString}`,
      providesTags: ["match"],
    }),
    getExportMatches: builder.query({
      query: (id) => `/match/export/${id}`,
    }),
    getTournamentMatches: builder.query({
      query: (id) => `/match/tournament/${id}`,
      providesTags: ["match"],
    }),
    matchTables: builder.mutation({
      query: ({ id, data }) => ({
        url: `/match/table/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),
    matchResult: builder.mutation({
      query: ({ id, data }) => ({
        url: `/match/result/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),

    createChallenge: builder.mutation({
      query: (data) => ({
        url: `/match/createChallenge`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["challenge"],
    }),

    createChallengeMatch: builder.mutation({
      query: (data) => ({
        url: `/match/challenge`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["challenge"],
    }),

    updateChallenge: builder.mutation({
      query: (data) => ({
        url: `/match/updateChallenge/${data.id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["challenge"],
    }),

    getChallenges: builder.query({
      query: (id) => `/match/getChallenges/${id}`,
      providesTags: ["match"],
    }),
    getChallengeMatches: builder.query({
      query: (query) => {
        debugger;
        return `/match/challengeMatches?${query}`;
      },
      providesTags: ["match"],
    }),

    //group
    getDrawGroups: builder.query({
      query: (id) => `/group/draw/${id}`,
      providesTags: ["match"],
    }),
    groupTables: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/tables/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["draw", "match"],
    }),
    addNewPlayerSE: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/newPlayerSE/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["event-players", "entry", "unassigned", "draw"],
    }),
    addNewPlayerRR: builder.mutation({
      query: ({ id, data }) => ({
        url: `/group/newPlayerRR/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: [
        "event-players",
        "entry",
        "unassigned",
        "draw",
        "match",
      ],
    }),

    // entry builders
    getTournamentFinances: builder.query({
      query: (id) => `/entry/tournamentFinances/${id}`,
      providesTags: ["entry"],
    }),
    getEventEntries: builder.query({
      query: (id) => `/entry/eventEntries/${id}`,
      providesTags: ["entry"],
    }),

    getLeagueEntries: builder.query({
      query: (id) => `/entry/leagueEntries/${id}`,
      providesTags: ["entry"],
    }),
    getLeagueEntriesUser: builder.query({
      query: (id) => `/entry/leagueEntries/user/${id}`,
      providesTags: ["entry"],
    }),
    getEventEntriesUser: builder.query({
      query: (id) => `/entry/eventEntries/user/${id}`,
      providesTags: ["entry"],
    }),
    getTournamentEntries: builder.query({
      query: (id) => `/entry/tournament/${id}`,
      providesTags: ["entry"],
    }),
    getTournamentEntriesUser: builder.query({
      query: (id) => `/entry/tournament/user/${id}`,
      providesTags: ["entry"],
    }),

    entryCheckin: builder.mutation({
      query: (data) => ({
        url: `/checkin/add`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["entry"],
    }),

    entryNote: builder.mutation({
      query: ({ id, data }) => ({
        url: `/entry/note/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["entry"],
    }),
    rating: builder.mutation({
      query: ({ id, data }) => ({
        url: `/entry/rating/${id}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["entry"],
    }),

    //clubs
    getAllClubs: builder.query({
      query: (query) => `/clubs/all/user${query}`,
      providesTags: ["clubs"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useSignupMutation,
  useCreateProfileMutation,
  useConnectProfileMutation,
  useUpdateProfileMutation,
  useGetAllTournamentsQuery,
  useGetQueryTournamentsQuery,
  useAddNewTournamentMutation,
  useDuplicateTournamentMutation,
  useUpdateTournamentMutation,
  useGetAllLeaguesQuery,
  useAddNewLeagueMutation,
  useDuplicateLeagueMutation,
  useUpdateLeagueMutation,
  useGetLeagueByIdQuery,
  useGetQueryLeaguesQuery,

  useGetTournamentByIdQuery,

  useGetAllEventsQuery,

  useGetQueryEventsByTournamentIdQuery,

  useLazyGetQueryEventsByTournamentIdQuery,
  useLazyGetLeagueDrawUserQuery,
  useGetLeagueDrawDatesQuery,

  useLazyGetEventsByTournamentIdQuery,
  useAddNewEventMutation,
  useUpdateEventMutation,
  useOpenCloseEventMutation,
  useGetEventByIdQuery,
  useGetPlayersByEventIdQuery,
  useGetPlayersByLeagueIdQuery,
  useGetPlayersByLeagueDrawIdQuery,
  useGetEventEligiblePlayersQuery,
  useLazyGetUnAssignedPlayersQuery,
  useLazyGetUnAssignedLeaguePlayersQuery,
  useGetEventsByTournamentIdQuery,
  useGetAllPlayerQuery,
  useAddNewPlayerMutation,
  useAddNewLeaguePlayerMutation,
  useUpdatePlayerMutation,
  useSelfRegistrationMutation,
  useSelfRegistratioForLeagueMutation,
  useGetPlayerByIdQuery,
  useGetPlayerEntriesQuery,
  useGetPlayersByTournamentIdQuery,
  useSearchPlayerQuery,
  useSearchByIdQuery,
  useGetTableByIdQuery,
  useGetTableMatchesQuery,
  useGetTournamentTablesQuery,
  useGetLeagueTablesQuery,
  useAddNewTableMutation,
  useAddNewLeagueTableMutation,
  useDeleteTableMutation,
  useAddNewDrawMutation,
  useDisplayDrawQuery,
  useUpdateDrawMutation,
  useDeleteDrawMutation,
  useResetDrawMutation,
  useFinalDrawMutation,
  useAddNewLeagueDrawMutation,
  useDeleteLeagueDrawMutation,
  useUpdateLeagueDrawMutation,
  useResetLeagueDrawMutation,
  useFinalLeagueDrawMutation,
  useLeagueDrawSeedingMutation,
  useGetLeagueDrawQuery,
  useGetLeagueDrawUserQuery,
  useLeagueEnrollmentMutation,

  useDrawSeedingMutation,
  useActivateKnockoutMutation,
  useActivateLeagueKnockoutMutation,
  useGroupTablesMutation,
  useGetEventDrawQuery,
  useLazyGetEventDrawUserQuery,
  useImportPlayerMutation,
  useEventEnrollmentMutation,
  useEventRemovePlayerMutation,
  useLeagueRemovePlayerMutation,
  useAddNewPlayerRRMutation,
  useAddNewPlayerSEMutation,
  useDeletePlayerRRMutation,
  useGetTournamentFinancesQuery,
  useGetEventEntriesQuery,
  useLazyGetEventEntriesQuery,
  useLazyGetLeagueEntriesQuery,
  useGetTournamentEntriesQuery,
  useLazyGetTournamentEntriesQuery,
  useGetTournamentEntriesUserQuery,
  useGetLeagueEntriesUserQuery,
  useLazyGetEventEntriesUserQuery,
  useLazyGetLeagueEntriesUserQuery,
  useEntryCheckinMutation,
  useEntryNoteMutation,
  useRatingMutation,
  useGetGroupMatchesQuery,
  useGetPlayerMatchesQuery,
  useGetEventMatchesQuery,
  useGetDrawGroupsQuery,
  useMatchTablesMutation,
  useMatchResultMutation,
  useCreateChallengeMatchMutation,
  useCreateChallengeMutation,
  useGetChallengesQuery,
  useGetChallengeMatchesQuery,
  useUpdateChallengeMutation,
  useGetExportMatchesQuery,
  useGetMatchByTournamentIdQuery,
  useGetMatchByLeagueIdQuery,
  useGetAllClubsQuery,
  useLazyGetAllClubsQuery,
} = apiSlice;
