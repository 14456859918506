import { configureStore } from "@reduxjs/toolkit/react";
import userReducer from "./features/userSlice";
import tournamentReducer from "./features/tournamentSlice";
import leagueReducer from "./features/leagueSlice";
import leagueEntryReducer from "./features/leagueEntrySlice";
import leagueDrawReducer from "./features/leagueDrawSlice";
import eventReducer from "./features/eventSlice";
import teamReducer from "./features/teamSlice";
import playerReducer from "./features/playerSlice";
import tableReducer from "./features/tableSlice";
import { apiSlice } from "./features/api/apiSlice";
import entryReducer from "./features/entrySlice";
import drawReducer from "./features/drawSlice";
import matchReducer from "./features/matchSlice";
import groupReducer from "./features/groupSlice";
import clubReducer from "./features/clubSlice";
import challengeReducer from "./features/challengeSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    tournaments: tournamentReducer,
    leagues: leagueReducer,
    leagueDraws: leagueDrawReducer,
    events: eventReducer,
    teams: teamReducer,
    players: playerReducer,
    tables: tableReducer,
    entries: entryReducer,
    leagueEntries: leagueEntryReducer,
    draws: drawReducer,
    matches: matchReducer,
    groups: groupReducer,
    clubs: clubReducer,
    challenges: challengeReducer,

    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
