import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useActivateLeagueKnockoutMutation,
  useAddNewPlayerRRMutation,
  useAddNewPlayerSEMutation,
  useFinalLeagueDrawMutation,
  useGetLeagueDrawQuery,
  useLazyGetUnAssignedLeaguePlayersQuery,
  useResetLeagueDrawMutation,
} from "../../../redux/features/api/apiSlice";
import loadingImg from "../../../images/loading-image.svg";
import { useSelector } from "react-redux";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/16/solid";
import { toast } from "react-toastify";
import GroupCard from "../../card/Group";
import { format } from "date-fns";
import SingleElimination from "../draws/SingleElimination";
import FinalizedSingleElimination from "../draws/FinalizedSingleElimination";
import AddLeagueDrawForm from "../../../pages/admin/Events/AddLeagueDrawForm";
import ThursdayFormat from "../draws/ThursdayFormat";
import TuesdayFormat from "./../draws/TuesdayFormat";

const LeagueDrawTab = ({ league, leagueDrawDates }) => {
  let { leagueId } = useParams();
  const [finalDraw, { isLoading: finalizing }] = useFinalLeagueDrawMutation();
  const [newPlayerRR, { isLoading: addingPlayerRR }] =
    useAddNewPlayerRRMutation();
  const [newPlayerSE, { isLoading: addingPlayerSE }] =
    useAddNewPlayerSEMutation();

  const [currentLeagueDrawDate, setCurrentLeagueDrawDate] = useState("");
  const [activateKnockout] = useActivateLeagueKnockoutMutation();

  const [resetDraw] = useResetLeagueDrawMutation();
  useEffect(() => {
    let localDate = localStorage.getItem("leagueDrawDate");

    if (localDate && localDate !== "undefined") {
      localDate = JSON.parse(localDate);
      setCurrentLeagueDrawDate(localDate);
    }
  }, [leagueDrawDates]);
  const { isFetching, refetch } = useGetLeagueDrawQuery({
    id: leagueId,
    leagueDrawDate: currentLeagueDrawDate,
  });

  let { leagueDraw } = useSelector((state) => state.leagueDraws);

  const { leagueDraws } = useSelector((state) => state);

  const [fetchUnAssigned] = useLazyGetUnAssignedLeaguePlayersQuery();

  const { unassigned } = useSelector((state) => state.leagueDraws);

  const [drawForm, setDrawForm] = useState(false);
  const [deleteDraw, setDelteDraw] = useState(false);
  const [addPlayer, setAddPlayer] = useState(false);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [seeding, setSeeding] = useState(true);

  const [stage, setStage] = useState(
    leagueDraw?.thursdayFormatSettings?.activateSE || false
  );

  const [confirmActivate, setConfirmActivate] = useState(false);
  const [confirmFinalize, setConfirmFinalize] = useState(false);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);

  const handleDrawForm = () => {
    setDrawForm(false);
  };

  const handleDeleteDraw = () => {
    setDelteDraw(!deleteDraw);
  };

  const handlePrevDate = () => {
    setCurrentDateIndex((prevIndex) => Math.max(prevIndex - 1, 0));

    setCurrentLeagueDrawDate(leagueDrawDates[currentDateIndex].leagueDrawDate);
    localStorage.setItem(
      "leagueDrawDate",
      JSON.stringify(leagueDrawDates[currentDateIndex].leagueDrawDate)
    );
  };

  const handleNextDate = () => {
    setCurrentDateIndex((prevIndex) =>
      Math.min(prevIndex + 1, leagueDrawDates.length - 1)
    );
    setCurrentLeagueDrawDate(leagueDrawDates[currentDateIndex].leagueDrawDate);
    localStorage.setItem(
      "leagueDrawDate",
      JSON.stringify(leagueDrawDates[currentDateIndex].leagueDrawDate)
    );
  };

  leagueDraw = leagueDraw[0];
  if (leagueDraw) {
    localStorage.setItem("leagueDrawId", JSON.stringify(leagueDraw.id));
  }
  let date =
    leagueDraw?.leagueDrawDate || new Date().toISOString().split("T")[0]; // Fallback to today's date

  useEffect(() => {
    if (!isFetching && leagueDraw && date) {
      fetchUnAssigned({ id: leagueId, date });
    }
  }, [isFetching, leagueDraw, currentDateIndex, leagueId]);

  const checkClubConflictRR = () => {
    for (const group of leagueDraw.groups) {
      const clubCount = {};

      for (const groupPlayer of group.groupPlayers) {
        const clubId = groupPlayer.player?.club?.id;
        const clubName = groupPlayer.player?.club?.clubName;

        if (clubId) {
          clubCount[clubId] = (clubCount[clubId] || 0) + 1;

          if (clubCount[clubId] > 1) {
            return `Group ${
              group.number + 1
            } has 2 or more players from the same club (Club Name: ${clubName}).`;
          }
        }
      }
    }
    return null;
  };

  const checkClubConflictSE = () => {
    const sortedGroups = [...leagueDraw?.groups].sort(
      (a, b) => a.number - b.number
    );

    const sortedPlayers = [...sortedGroups[0].groupPlayers].sort(
      (a, b) => a.order - b.order
    );

    for (let i = 0; i < sortedPlayers.length; i += 2) {
      const player1 = sortedPlayers[i];
      const player2 = sortedPlayers[i + 1];

      if (player1 && player2) {
        const clubId1 = player1?.player?.club?.id;
        const clubId2 = player2?.player?.club?.id;

        if (clubId1 && clubId2 && clubId1 === clubId2) {
          const clubName = player1?.player?.club?.clubName; // Both players belong to the same club
          return `Match ${Math.ceil(
            (i + 1) / 2
          )} has players from the same club (Club Name: ${clubName}).`;
        }
      }
    }
    return null; // No conflicts found
  };

  const finalizeDraw = async () => {
    try {
      const res = await finalDraw({
        id: leagueDraw.id,
        data: { finalized: !leagueDraw.finalized, leagueId },
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
        window.location.reload();
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setConfirmFinalize();
    }
  };

  const handleNewPlayerRR = async (id, data) => {
    try {
      const res = await newPlayerRR({
        id,
        data,
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIndex(null);
      setAddPlayer(false);
      refetch();
    }
  };
  console.log(leagueDraw);
  const handleNewPlayerSE = async (id, data) => {
    try {
      const res = await newPlayerSE({
        id,
        data,
      });
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.warning(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIndex(null);
      setAddPlayer(false);
    }
  };

  const handleActivateKnockout = async () => {
    if (loading) return;
    setLoading(true);

    try {
      let thursdayFormatSettings = { ...leagueDraw?.thursdayFormatSettings }; // Create a copy of thursdayFormatSettings
      thursdayFormatSettings.activateSE = !thursdayFormatSettings.activateSE; // Toggle activateSE

      const res = await activateKnockout({
        id: leagueDraw.id,
        data: { thursdayFormatSettings, leagueId }, // Send updated thursdayFormatSettings
      });

      if (res?.data?.error === false) {
        toast.success(res.data.data);
      } else if (res?.error?.data?.error) {
        toast.error(res.error.data.error);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
      setConfirmActivate(false);
    }
  };

  const handleResetDraw = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await resetDraw(leagueDraw.id);
      if (res?.data?.error === false) {
        toast.success(res.data.data);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  console.log(currentLeagueDrawDate);

  useEffect(() => {
    refetch();
  }, [leagueId, leagueDrawDates, currentLeagueDrawDate, deleteDraw]);

  const renderFinalizeModal = () => {
    let error = null;
    if (!leagueDraw.finalized) {
      if (leagueDraw?.leagueDrawFormat === "THF") {
        error = checkClubConflictRR();
      } else {
        error = checkClubConflictSE();
      }
    }
    return (
      <div
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
        onClick={() => setConfirmFinalize(false)}
      >
        <div
          className="bg-white rounded-lg p-5"
          onClick={(e) => e.stopPropagation()} // Prlegue click propagation
        >
          <div className="text-lg text-gray-800 text-center font-bold">
            Are you sure?
          </div>
          <div className="text-xs text-gray-500 text-center max-w-md mt-5">
            {leagueDraw?.finalized
              ? "All the current Match data and Results will be lost for this draw."
              : "The Draw settings will be finalized and Event Matches will start."}
          </div>
          {!leagueDraw?.finalized && (
            <>
              {error && (
                <div className="text-red-500 text-center font-medium text-sm mt-3">
                  {error}
                </div>
              )}
              {unassigned && unassigned.length > 0 && (
                <div className="text-red-500 text-center font-medium text-sm mt-3">
                  Some New Players are not included in the draw yet!
                </div>
              )}
            </>
          )}
          <div className="flex justify-center mt-10 gap-10 text-white font-medium">
            <button
              className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
              onClick={() => setConfirmFinalize(false)}
            >
              Cancel
            </button>
            <button
              className={`text-sm px-5 py-2 rounded ${
                leagueDraw?.finalized
                  ? "bg-red-600 hover:bg-red-700"
                  : "bg-green-600 hover:bg-green-700"
              } border`}
              onClick={finalizeDraw}
              disabled={finalizing} // Disable button if loading
            >
              {finalizing
                ? "Finalizing..."
                : error
                ? "Confirm Anyway"
                : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {(isFetching || addingPlayerRR || addingPlayerSE) && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loadingImg} alt="" />
          </div>
        </div>
      )}
      {drawForm ? (
        <AddLeagueDrawForm
          handleCloseForm={handleDrawForm}
          handleDeleteForm={handleDeleteDraw}
          league={league}
          leagueDraw={leagueDraw}
          finalized={leagueDraw?.finalized}
          refetch={refetch}
        />
      ) : (
        <>
          {confirmFinalize && renderFinalizeModal()}
          {confirmActivate && (
            <div
              className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
              onClick={() => setConfirmActivate(false)}
            >
              <div
                className="bg-white rounded-lg p-5"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="text-lg text-gray-800 text-center font-bold">
                  Are you sure?
                </div>
                <div className="text-xs text-gray-500 text-center max-w-md">
                  {"("}Knockout Stage is not started yet! Activate to Start,
                  Once Stated cannot go back to Group Stage{")"}
                </div>
                <div className="flex justify-center mt-10 gap-10 text-white font-medium">
                  <button
                    className="text-sm px-5 py-2 rounded bg-gray-400 hover:bg-gray-500 border"
                    onClick={() => setConfirmActivate(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="text-sm px-5 rounded py-2 bg-green-600 hover:bg-green-700 border"
                    onClick={handleActivateKnockout}
                  >
                    {loading ? "loading.." : "Confirm"}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="w-full bg-gray-200 rounded mt-5">
            {leagueDrawDates?.length > 0 && (
              <div className="flex justify-center items-center gap-10 p-1">
                <button
                  onClick={handlePrevDate}
                  disabled={currentDateIndex === 0}
                  className="w-8 h-8 p-2 bg-gray-300 rounded-md hover:bg-gray-400"
                >
                  <ChevronLeftIcon />
                </button>

                <div className="text-gray-700 font-medium">
                  <span>
                    {format(
                      new Date(
                        currentLeagueDrawDate ||
                          leagueDrawDates[0].leagueDrawDate
                      ),
                      "EEE MMM dd yyyy"
                    )}
                  </span>
                </div>

                <button
                  onClick={handleNextDate}
                  disabled={currentDateIndex === leagueDrawDates.length - 1}
                  className="w-8 h-8 p-2 bg-gray-300 rounded-md hover:bg-gray-400"
                >
                  <ChevronRightIcon />
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col items-start justify-start w-full mt-8">
            {leagueDraw?.id ? (
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="flex items-start gap-2 mt-5 mb-10">
                    <button onClick={() => setDrawForm(true)} className="mt-1">
                      <PencilSquareIcon height={20} width={20} />
                    </button>

                    <div className=" ">
                      <div className="flex items-center gap-3">
                        <div className="text-lg font-bold">
                          {leagueDraw?.leagueDrawName}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-2">
                        <div className="text-xs font-medium text-gray-600">
                          Estemated Start: {league?.startDate?.split("T")[0]},{" "}
                        </div>
                        <div className="text-xs font-medium text-gray-600">
                          {" "}
                          {league?.startTime}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          Format:{" "}
                          {leagueDraw?.leagueDrawFormat === "THF"
                            ? "Thursday Format"
                            : "Tuesday Format"}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 mt-1">
                        <div className="text-xs font-medium text-gray-600">
                          First to{" "}
                          {leagueDraw?.defaultMatchFormat?.pointsPerGame} (Win
                          by {leagueDraw?.defaultMatchFormat?.deuce ? "2" : "1"}{" "}
                          ) <span className="text-sm font-black">.</span> Best
                          of {leagueDraw?.defaultMatchFormat?.gamesPerMatch}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={() => setConfirmFinalize(true)}
                      className={` ${
                        leagueDraw?.finalized ? "bg-red-600" : "bg-blue-700"
                      } rounded shadow border text-white text-xs sm:text-sm px-3 py-2`}
                    >
                      {leagueDraw?.finalized ? "Undo Draw" : "Finalize Draw"}
                    </button>
                  </div>
                </div>
                <div>
                  <div className="space-y-0.5">
                    {unassigned &&
                      ((!leagueDraw?.finalized &&
                        leagueDraw.leagueDrawFormat === "TF") ||
                        (leagueDraw?.leagueDrawFormat === "THF" &&
                          !leagueDraw?.thursdayFormatSettings?.activateSE)) &&
                      unassigned.map((player, playerIndex) => (
                        <div
                          key={playerIndex}
                          className={`flex justify-between items-center border shadow rounded-md px-3 py-1 bg-gray-300`}
                        >
                          <div className="bg-white min-w-56 rounded p-1">
                            {player && (
                              <div className="text-[10px] text-gray-500 flex gap-1">
                                <div className="">{player?.rating || ""}</div>
                                {player?.club && (
                                  <>
                                    <span className="font-bold text-black text-xs -mt-0.5">
                                      .
                                    </span>
                                    <div className="text-[10px] text-gray-500 font-medium">
                                      {player?.club?.clubName},{" "}
                                      {player?.club?.region},{" "}
                                      {player?.club?.state}
                                    </div>
                                  </>
                                )}
                              </div>
                            )}

                            <div className="text-sm font-medium text-gray-700">
                              {player
                                ? `${player.firstName}, ${player.lastName}`
                                : "TBD"}
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              setAddPlayer(!addPlayer);
                              setIndex(playerIndex);
                            }}
                            className="px-3 bg-white hover:bg-gray-100 rounded py-2 border border-black flex items-center"
                          >
                            Add Player <ChevronDownIcon className="h-5 w-5" />
                          </button>
                          {addPlayer && playerIndex === index && (
                            <div className="absolute bg-gray-50 p-3 border rounded shadow right-8 mt-4">
                              {leagueDraw?.leagueDrawFormat === "THF" ||
                              leagueDraw?.leagueDrawFormat === "TF" ? (
                                <div className="grid space-y-1">
                                  {leagueDraw?.groups
                                    ?.filter((g) => g.type === "RR")
                                    .map((group, i) => (
                                      <button
                                        key={i}
                                        onClick={() =>
                                          handleNewPlayerRR(group.id, {
                                            player,
                                            leagueId,
                                            leagueDrawId: leagueDraw.id,

                                            finalized: leagueDraw.finalized,
                                          })
                                        }
                                        className="grid bg-gray-200 hover:bg-gray-300 px-3 rounded py-1"
                                      >
                                        Group {group?.number + 1}
                                      </button>
                                    ))}
                                </div>
                              ) : (
                                <div className="grid space-y-1">
                                  {leagueDraw?.groups
                                    ?.find((group) => group.number === 0)
                                    ?.groupPlayers?.filter(
                                      (player) => player.player === null
                                    )
                                    .sort((a, b) => a.order - b.order)
                                    ?.map((place, i) => (
                                      <button
                                        key={i}
                                        onClick={() =>
                                          handleNewPlayerSE(
                                            leagueDraw.groups.find(
                                              (group) => group.number === 0
                                            )?.id,
                                            {
                                              place,
                                              player,
                                            }
                                          )
                                        }
                                        className="grid bg-gray-200 hover:bg-gray-300 px-3 rounded py-1"
                                      >
                                        Place {place?.order + 1}
                                      </button>
                                    ))}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="flex justify-center mt-5">
                    {((!leagueDraw?.finalized &&
                      leagueDraw.leagueDrawFormat === "TF") ||
                      (leagueDraw?.leagueDrawFormat === "THF" &&
                        !leagueDraw?.thursdayFormatSettings?.activateSE) ||
                      !leagueDraw?.tuesdayFormatSettings?.activateSE) &&
                      unassigned?.length > 0 &&
                      !leagueDraw?.finalized && (
                        <button
                          onClick={handleResetDraw}
                          className={`   bg-green-600 rounded shadow hover:shadow-lg border text-white px-3 py-2`}
                        >
                          Auto Add All Players
                        </button>
                      )}
                  </div>
                </div>
                <div className="my-5 flex gap-3 text-xs sm:text-sm">
                  {!leagueDraw?.finalized && (
                    <>
                      <button
                        onClick={handleResetDraw}
                        className={` border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                      >
                        Reset to Default
                      </button>
                      <button
                        onClick={() => setSeeding(!seeding)}
                        className={`   border-blue-600 rounded shadow hover:shadow-lg border text-blue-700 px-3 py-2`}
                      >
                        Seeding Rules {seeding ? "On" : "Off"}
                      </button>
                    </>
                  )}
                </div>

                {leagueDraw?.leagueDrawFormat === "THF" ||
                leagueDraw?.leagueDrawFormat === "TF" ? (
                  <>
                    {leagueDraw?.finalized ? (
                      <div className="w-full">
                        <div className="w-full flex gap-3 justify-center text-xs sm:text-sm mb-10"></div>

                        <div className="w-full grid grid-col-1 md:grid-cols-2 gap-1  lg:gap-5">
                          {leagueDraw?.groups &&
                            leagueDraw?.groups?.length &&
                            leagueDraw.groups
                              .filter((group) => group.type === "RR")
                              .slice()
                              .sort((a, b) => a.number - b.number)
                              .map((group, i) => (
                                <GroupCard
                                  group={group}
                                  roundRobbin={
                                    leagueDraw.leagueDrawFormat === "THF"
                                      ? leagueDraw?.thursdayFormatSettings
                                      : leagueDraw?.tuesdayFormatSettings
                                  }
                                  key={i}
                                  admin={true}
                                  refetch={refetch}
                                />
                              ))}
                        </div>
                      </div>
                    ) : (
                      <>
                        {leagueDraw?.leagueDrawFormat === "TF" ? (
                          <TuesdayFormat
                            leagueDraw={leagueDraw}
                            seeding={seeding}
                            refetch={refetch}
                          />
                        ) : (
                          <ThursdayFormat
                            leagueDraw={leagueDraw}
                            seeding={seeding}
                            refetch={refetch}
                          />
                        )}
                      </>
                    )}{" "}
                  </>
                ) : (
                  <div>
                    {leagueDraw?.finalized ? (
                      <FinalizedSingleElimination
                        leagueDrawId={leagueDraw?.id}
                        groups={leagueDraw?.groups.filter(
                          (g) => g.type === "SE"
                        )}
                        admin={true}
                      />
                    ) : (
                      <>
                        <SingleElimination
                          leagueDraw={leagueDraw}
                          seeding={seeding}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="w-full flex justify-center">
                <div className="text-center mt-20 text-gray-500">
                  No Draw Created Yet!
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LeagueDrawTab;
