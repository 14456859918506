import React, { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import { UserPlusIcon, ShieldExclamationIcon } from "@heroicons/react/24/solid";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLeagueEnrollmentMutation } from "../../../redux/features/api/apiSlice";

const ManageLeaguePlayer = () => {
  const { leagueId } = useParams();
  const [search, setSearch] = useState("");
  const [players, setPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const fetchPlayers = async (reset = false) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/players/leagueEligible?leagueId=${leagueId}&search=${search}&page=${page}`
      );
      if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
      }

      const data = await response.json();
      if (reset) {
        setPlayers(data);
      } else {
        setPlayers((prev) => [...prev, ...data]);
      }
      setHasMore(data.length === 20); // Assuming the server sends 20 players per request
    } catch (error) {
      console.error("Error fetching players:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = debounce((value) => {
    setSearch(value);
    setPage(1); // Reset the page to 1
  }, 1000);

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value.length >= 2 || value.length === 0) {
      debouncedSearch(value);
    }
  };

  useEffect(() => {
    fetchPlayers(true);
  }, [search]);

  useEffect(() => {
    if (page > 1) {
      fetchPlayers();
    }
  }, [page]);

  const loadMorePlayers = () => {
    setPage((prev) => prev + 1);
  };

  const [enrollPlayer, { isLoading: enrolling }] =
    useLeagueEnrollmentMutation();
  const [index, setIndex] = useState("");

  const handlePlayerEnrollment = async (data) => {
    try {
      let leagueDrawId = localStorage.getItem("leagueDrawId");
      leagueDrawId = JSON.parse(leagueDrawId);
      const res = await enrollPlayer({
        data: { playerId: data.playerId, enroll: true, leagueId, leagueDrawId },
      });
      if (res?.data?.error === false) {
        toast.success("Player Added");
        fetchPlayers(true);
      }
      if (res?.error?.data?.error !== undefined) {
        toast.error(res?.error?.data?.error);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIndex("");
    }
  };

  return (
    <div className="p-1">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or member ID"
          onChange={handleSearch}
          className="p-2 border rounded w-full"
        />
      </div>

      <table className="w-full text-sm text-left shadow text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-300">
          <tr>
            <th className="px-6 py-3 w-[90%]">Player</th>
            <th className="px-6 py-3 w-[10%]">Add</th>
          </tr>
        </thead>
        <tbody>
          {players &&
            players.map((player, i) => (
              <tr
                key={i}
                className="odd:bg-gray-100 font-semibold even:bg-gray-200 border-b"
              >
                <th
                  scope="row"
                  className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap "
                >
                  <div className="bg-white rounded-xl border shadow px-3">
                    <div className="flex justify-between">
                      <div className="flex gap-3">
                        <div className="text-[10px] text-gray-500">
                          {player?.rating}
                        </div>
                        {player?.club ? (
                          <div className="text-[10px] text-gray-500 font-medium">
                            {player?.club?.clubName}, {player?.club?.region},{" "}
                            {player?.club?.state}
                          </div>
                        ) : (
                          <div className="text-[10px] text-gray-500 font-medium">
                            {player?.homeAddress}
                          </div>
                        )}
                      </div>
                      {player?.membershipExpiryDate <
                        new Date().toDateString() && (
                        <div
                          title="Membership Expired"
                          className="w-10 flex justify-center"
                        >
                          <ShieldExclamationIcon className="text-red-500 h-4 w-4" />
                        </div>
                      )}
                    </div>

                    <div className="text-lg">
                      {player?.lastName}, {player?.firstName}
                    </div>
                  </div>
                </th>
                <td className="px-6 py-1 text-gray-900 whitespace-nowrap">
                  <button
                    className="flex flex-col items-center justify-center w-full"
                    onClick={() => {
                      handlePlayerEnrollment({
                        playerId: player.id,
                      });
                      setIndex(i);
                    }}
                  >
                    {enrolling && index === i ? (
                      <div className="flex items-center justify-center">
                        <svg
                          className="animate-spin h-5 w-5 text-gray-100"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                          ></path>
                        </svg>
                      </div>
                    ) : (
                      <UserPlusIcon className="w-6 h-6 text-green-500 hover:h-7 hover:w-7 hover:text-green-600" />
                    )}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {hasMore && !search && (
        <div className="text-center my-4">
          <button
            onClick={loadMorePlayers}
            disabled={isLoading}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
          >
            {isLoading ? "Loading..." : "Load More"}
          </button>
        </div>
      )}

      {search && players.length === 0 && (
        <div className="text-center text-gray-500 my-4">
          No players found for your search.
        </div>
      )}
    </div>
  );
};

export default ManageLeaguePlayer;
