import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";

export const tournamentInitialState = {
  tournamentName: "",
  membershipSystem: "none",
  startDate: new Date(),
  endDate: new Date(),
  ratingCutoffDate: new Date(),
  timezone: "est",
  email: "",
  phone: "",
  country: "",
  note: "",
  city: "",
  state: "",
  zip: "",
  address1: "",
  tournamentCurrencyType: "$",
  tournamentFee: 0,
  customSettings: {
    enablePlayerList: true,
    enableAutoCertifyResults: true,
    enableSelfRegistrations: true,
    enableSimpleVerification: true,
  },
  teamRatingFormula: "balanced",
  tournamentManagers: [],
  players: [],
};

export const initialState = {
  tournaments: [],
  queryTournaments: [],
  tournament: {},
};

export const tournamentSlice = createSlice({
  name: "tournament",
  initialState: initialState,
  reducers: {
    setTournament: (state, action) => {
      state.currentTournament = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getAllTournaments.matchFulfilled,
      (state, action) => {
        state.tournaments = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getQueryTournaments.matchFulfilled,
      (state, action) => {
        state.queryTournaments = action.payload.data;
      }
    );
    builder.addMatcher(
      apiSlice.endpoints.getTournamentById.matchFulfilled,
      (state, action) => {
        state.tournament = action.payload.data[0];
      }
    );
  },
});

// Action creators are generated for each case reducer function
export const { setTournament } = tournamentSlice.actions;

export default tournamentSlice.reducer;
