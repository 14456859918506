import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetTournamentTablesQuery,
  useAddNewTableMutation,
} from "../../../redux/features/api/apiSlice";
import { TableCard } from "../../card/Table";
import { toast } from "react-toastify";
import loading from "../../../images/loading-image.svg";

const TablesTab = () => {
  let { tournamentId } = useParams();
  const [addNewTable] = useAddNewTableMutation();
  const { isFetching } = useGetTournamentTablesQuery(tournamentId);
  const { tables } = useSelector((state) => state.tables) || [];
  const [amount, setAmount] = useState(1);
  const [error, setError] = useState("");
  const [tloading, setTLoading] = useState("");

  const handleNewTables = async () => {
    if (tloading) return;
    setTLoading(true);
    try {
      if (amount > 0 && amount < 51) {
        setError("");
        const res = await addNewTable({
          id: tournamentId,
          data: { amount: amount },
        });
        if (!res.data.error) {
          toast.success("Tables Added!");
        }
      } else {
        setError("you can create max 50 tables at once");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setTLoading(false);
    }
  };

  return (
    <>
      {isFetching && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay">
          <div className="  relative mx-3">
            <img className="" src={loading} alt="" />
          </div>
        </div>
      )}

      <div className="py-10">
        <div className="flex justify-between">
          <div className="text-xl font-medium text-gray-600">All Tables</div>
          <div className="relative flex gap-3">
            <input
              type="number"
              min={1}
              max={50}
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="py-2 px-3 w-[90px] shadow rounded border border-gray-300 text-sm"
            />
            <button
              disabled={amount < 1 || amount > 50}
              onClick={handleNewTables}
              className={`text-sm py-2 px-3 ${
                amount < 1 || amount > 50 ? "bg-gray-300" : "bg-blue-700"
              }  text-white border shadow rounded hover:shadow-lg`}
            >
              {tloading ? "loading.." : "Add Table"}
            </button>
            {error && (
              <p className="absolute mt-10 text-center text-xs font-medium text-red-700 capitalize">
                {error}
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3 mt-10">
          {tables
            ?.slice()
            ?.sort((a, b) => a.number - b.number)
            ?.map((table, i) => (
              <TableCard
                table={table}
                key={i}
                index={i}
                length={tables.length}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default TablesTab;
