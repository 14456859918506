import React, { useState } from "react";
import {
  useLeagueDrawSeedingMutation,
  useDeletePlayerRRMutation,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";
import { TrashIcon } from "@heroicons/react/16/solid";

const TuesdayFormat = ({ leagueDraw, seeding, refetch }) => {
  const [drawSeeding] = useLeagueDrawSeedingMutation();
  const [player1, setPlayer1] = useState("");
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [hoveredGroup, setHoveredGroup] = useState(null);

  const [deletePlayer] = useDeletePlayerRRMutation();
  const handleDeletePlayer = async (player) => {
    try {
      const res = await deletePlayer({ id: player.groupId, data: player });
      if (!res?.data?.error) {
        toast.success(res.data.data);
        refetch();
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };
  const handleSeeding = async (data, order) => {
    try {
      if (player1?.groupId === data?.groupId) {
        setPlayer1(data);
        return;
      }
      if (player1 === "") {
        setPlayer1(data);
      } else {
        if (seeding && player1?.order !== data?.order) {
          setPlayer1(data);
          return;
        }

        const res = await drawSeeding({
          id: leagueDraw.id,
          data: { player1, data, seeding, order, format: "TF" },
        });
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          setPlayer1("");
        }
        if (res?.error?.data?.error !== undefined) {
          toast.success(res.error.data.error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-full grid grid-cols-4 gap-3">
        {leagueDraw?.groups &&
          leagueDraw.groups.length > 0 &&
          leagueDraw.groups
            .filter((group) => group.type === "RR") // Filter for SE type groups
            .sort((a, b) => a.number - b.number) // Sort by group number
            .map((group, i) => (
              <div className="" key={i}>
                <div className="font-semibold text-lg px-3 py-1">
                  Group {group.number + 1}
                </div>
                <div
                  onMouseEnter={() => setHoveredGroup(group.id)}
                  onMouseLeave={() => setHoveredGroup(null)}
                  className={`px-3 py-3 space-y-2 rounded-lg ${
                    !seeding && player1?.id && hoveredGroup === group?.id
                      ? "bg-green-200"
                      : ""
                  }`}
                >
                  {group?.groupPlayers?.length > 0 &&
                    [...group.groupPlayers]
                      .sort((a, b) => a.order - b.order)
                      .map((player, i) => (
                        <div className="relative">
                          <div
                            key={i}
                            onClick={() => handleSeeding(player)}
                            onMouseEnter={() => setHoveredPlayer(player)} // Track hovered player
                            onMouseLeave={() => setHoveredPlayer(null)}
                            className={`flex items-center gap-2 border shadow rounded-md px-3 py-1 cursor-pointer ${
                              player1?.id === player?.id ? `bg-gray-300` : ""
                            }
                                    ${
                                      seeding
                                        ? hoveredPlayer &&
                                          hoveredPlayer?.order ===
                                            player1?.order
                                          ? "hover:bg-green-400"
                                          : "hover:bg-red-400"
                                        : "hover:bg-green-400"
                                    }
                                    `}
                          >
                            <div className="h-5 w-5 rounded-full flex justify-center items-center bg-blue-300 text-white font-medium text-xs">
                              {player.order === 0
                                ? "A"
                                : player.order === 1
                                ? "B"
                                : player.order === 2
                                ? "C"
                                : player.order === 3
                                ? "D"
                                : player.order === 4
                                ? "E"
                                : player.order === 5
                                ? "F"
                                : player.order === 6
                                ? "G"
                                : player.order === 7
                                ? "H"
                                : player.order + 1}
                            </div>

                            <div className="border bg-white w-full px-2 rounded">
                              <div className="flex gap-1 text-[11px] text-gray-500">
                                <div>{player?.player?.rating}</div>

                                {player?.player?.club && (
                                  <>
                                    <span className="font-black text-black text-xs">
                                      .
                                    </span>
                                    <div className="text-[10px] text-gray-500 font-medium">
                                      {player?.player?.club?.clubName},{" "}
                                      {player?.player?.club?.region},{" "}
                                      {player?.player?.club?.state}
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="text-sm font-medium text-gray-700">
                                {player?.player?.firstName},{" "}
                                {player?.player?.lastName}
                              </div>
                            </div>
                          </div>
                          <button
                            onClick={() => handleDeletePlayer(player)}
                            className="absolute right-4 z-10 top-4 h-5 w-5 p-0.5 font-medium flex justify-center self-center items-center bg-red-400 text-white rounded-full hover:bg-red-600 "
                          >
                            <TrashIcon height={20} width={20} />
                          </button>
                        </div>
                      ))}
                  {!seeding &&
                    player1?.id &&
                    hoveredGroup === group?.id &&
                    player1?.groupId !== group.id && (
                      <button
                        onClick={() =>
                          handleSeeding(group.id, group?.groupPlayers?.length)
                        }
                        className="bg-green-400 py-2 rounded-lg text-center text-sm w-full hover:border hover:shadow hover:bg-green-500"
                      >
                        + Add Here
                      </button>
                    )}
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default TuesdayFormat;
