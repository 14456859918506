import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { drawsInitialState } from "../../../redux/features/drawSlice";
import FormFieldError from "../../../components/commons/formFeildError.";
import {
  useAddNewDrawMutation,
  useDeleteDrawMutation,
  useUpdateDrawMutation,
} from "../../../redux/features/api/apiSlice";
import { toast } from "react-toastify";

const AddDrawForm = ({
  handleCloseForm,
  handleDeleteForm,
  event,
  draw,
  finalized,
}) => {
  const [addNewDraw] = useAddNewDrawMutation();
  const [updateDraw] = useUpdateDrawMutation();
  const [deleteDraw] = useDeleteDrawMutation();
  drawsInitialState.eventId = event.id;

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: draw || drawsInitialState,
  });

  const drawFormat = watch("drawFormat");
  const ladderFormat = watch("roundRobinSettings.ladderFormat");
  const [drawId, setDrawId] = useState(draw?.drawName || "");

  const onSubmit = async (data) => {
    try {
      data.drawId = data.drawName;
      const { createdAt, id, updatedAt, ...cleanedData } = data;
      let res;
      if (draw?.id) {
        res = await updateDraw({ id: draw.id, data: cleanedData });
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          handleCloseForm();
          window.location.reload();
        }
        if (res?.error?.data?.error !== undefined) {
          toast.info(res.error.data.error);
        }
      } else {
        res = await addNewDraw(data);
        if (res?.data?.error === false) {
          toast.success(res.data.data);
          handleCloseForm();
          window.location.reload();
        }
        if (res?.error?.data?.error !== undefined) {
          toast.info(res.error.data.error);
        }
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  const addDrawId = (id) => {
    const newDrawId = id.toLowerCase();
    setDrawId(newDrawId);
  };

  const handleDelete = async () => {
    try {
      const res = await deleteDraw(draw.id);
      if (res?.data?.error === false) {
        toast.info(res.data.data);
        handleDeleteForm();
        handleCloseForm();
      }
      if (res?.error?.data?.error !== undefined) {
        toast.success(res.error.data.error);
      }
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };

  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-80 z-50 modal-overlay"
      onClick={handleCloseForm}
    >
      <div className="bg-white rounded-lg" onClick={(e) => e.stopPropagation()}>
        <form
          className="max-w-3xl mx-auto flex justify-end items-center text-xs sm:text-base"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="px-3 sm:px-10 pt-5 pb-10 rounded-lg space-y-1 border-2">
            <div className="grid items-start justify-between w-full grid-cols-12 gap-2">
              <div className="col-span-12 flex justify-end gap-3 mb-5">
                <button
                  type="button"
                  onClick={handleCloseForm}
                  className="inline-flex self-end justify-end px-3 py-2 text-sm font-semibold text-black border-2 border-black rounded-md shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="inline-flex self-end justify-end px-3 py-2 text-sm font-semibold text-white border-2 border-blue-700 bg-blue-700 rounded-md shadow-sm hover:bg-blue-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {draw?.id ? "Update" : "Submit"}
                </button>
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="drawsFormat"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Select Draw Format
                </label>
                <select
                  disabled={finalized}
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  id="drawsFormat"
                  {...register("drawFormat", {
                    required: "Draw format is required",
                  })}
                >
                  <option value="RR">Round Robin</option>
                  <option value="SE">Single Elimination</option>
                  <option value="NA">No Format (BETA)</option>
                </select>
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-5">
                <label
                  htmlFor="drawName"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Draw Name
                </label>
                <input
                  onInput={(e) => addDrawId(e.target.value)}
                  id="drawName"
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  {...register("drawName", {
                    required: "Draw Name is required",
                  })}
                />

                <FormFieldError errors={errors} field={"drawName"} />
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="drawId"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Draw Id
                </label>
                <input
                  value={drawId}
                  disabled={finalized}
                  className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0  text-sm sm:leading-6 "
                  id="drawId"
                  {...register("drawId")}
                />

                <FormFieldError errors={errors} field={"drawId"} />
              </div>
              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="pointsPerGame"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Points Per Game
                </label>
                <select
                  disabled={finalized}
                  className="w-full col-span-8 p-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  id="defaultMatchFormat.pointsPerGame"
                  type="number"
                  {...register("defaultMatchFormat.pointsPerGame")}
                >
                  <option>11</option>
                  <option>15</option>
                  <option>21</option>
                </select>
                <FormFieldError errors={errors} field={"pointsPerGame"} />
              </div>

              <div className="flex flex-col items-start justify-start w-full col-span-4 sm:col-span-3">
                <label
                  htmlFor="gamesPerMatch"
                  className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                >
                  Games Per Match
                </label>
                <select
                  disabled={finalized}
                  className="w-full col-span-8 p-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                  id="defaultMatchFormat.gamesPerMatch"
                  type="number"
                  {...register("defaultMatchFormat.gamesPerMatch")}
                >
                  <option>1</option>
                  <option>3</option>
                  <option>5</option>
                </select>
                <FormFieldError errors={errors} field={"gamesPerMatch"} />
              </div>
              <div className="hidden sm:flex sm:col-span-4"></div>
              <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="deuce"
                  className="w-full text-xs sm:text-sm font-medium leading-6 text-gray-900 "
                >
                  <input
                    disabled={finalized}
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="defaultMatchFormat.deuce"
                    type="checkbox"
                    {...register("defaultMatchFormat.deuce")}
                  />
                  Deuce{" "}
                  <span className="text-[10px] font-medium text-gray-500">
                    {" "}
                    : MUST WIN BY 2
                  </span>
                </label>
              </div>
              <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="playAllGames"
                  className="w-full text-xs sm:text-sm font-medium leading-6 text-gray-900 "
                >
                  <input
                    disabled={finalized}
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="defaultMatchFormat.playAllGames"
                    type="checkbox"
                    {...register("defaultMatchFormat.playAllGames")}
                  />
                  Play All Games
                </label>
              </div>
              <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                <label
                  htmlFor="scheduleMatches"
                  className="w-full text-xs sm:text-sm font-medium leading-6 text-gray-900 "
                >
                  <input
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="scheduleMatches"
                    type="checkbox"
                    {...register("scheduleMatches")}
                  />
                  Schedule Matches
                </label>
              </div>
              {drawFormat === "RR" && (
                <div className=" items-start justify-start w-full col-span-12 grid grid-cols-12 gap-2 mt-10">
                  <h2 className="text-base font-semibold uppercase col-span-12 text-blue-800">
                    Round robin Settings
                  </h2>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="roundRobinSettings.idealGroupSize"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Ideal Group Size
                    </label>
                    <input
                      disabled={finalized}
                      className="w-full p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="roundRobinSettings.idealGroupSize"
                      type="number"
                      min={3}
                      {...register("roundRobinSettings.idealGroupSize", {
                        required: "idealGroupSize Per Game is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors.roundRobinSettings}
                      field={"idealGroupSize"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="noAdvance"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      No Advance
                    </label>
                    <input
                      className="w-full col-span-8 p-1 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="noAdvance"
                      min={1}
                      max={3}
                      type="number"
                      {...register("roundRobinSettings.noAdvance", {
                        required: "noAdvance is required",
                      })}
                    />
                    <FormFieldError
                      errors={errors.roundRobinSettings}
                      field={"noAdvance"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="groupPreference"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Group Preference
                    </label>
                    <select
                      disabled={finalized}
                      className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="groupPreference"
                      {...register("roundRobinSettings.groupPreference", {
                        required: "groupPreference is required",
                      })}
                    >
                      <option value="small">smaller</option>
                      <option value="large">larger</option>
                    </select>
                    <FormFieldError
                      errors={errors.roundRobinSettings}
                      field={"drawFormat"}
                    />
                  </div>
                  <div className="flex flex-col items-start justify-start w-full col-span-6 sm:col-span-3">
                    <label
                      htmlFor="seeding"
                      className="block col-span-4 text-xs sm:text-sm font-medium leading-6 text-gray-900"
                    >
                      Seeding
                    </label>
                    <select
                      disabled={finalized}
                      className="w-full col-span-8 p-1.5 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                      id="seeding"
                      {...register("roundRobinSettings.seeding", {
                        required: "seedingis required",
                      })}
                    >
                      <option value="snake">Snake</option>
                      <option value="division">By Divisions</option>
                    </select>
                    <FormFieldError
                      errors={errors.roundRobinSettings}
                      field={"seeding"}
                    />
                  </div>
                  <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                    <label
                      htmlFor="useExactNoOfGroups"
                      className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                    >
                      <input
                        disabled={finalized}
                        className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                        id="roundRobinSettings.useExactNoOfGroups"
                        type="checkbox"
                        {...register("roundRobinSettings.useExactNoOfGroups")}
                      />
                      <span className="text-[10px] font-medium text-gray-500">
                        {" "}
                        USE EXACT NUM OF GROUPS
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-row items-start justify-start w-full col-span-6 sm:col-span-4">
                    <label
                      htmlFor="unratedPlayersToAdvance"
                      className="w-full text-sm  flex items-center font-medium leading-6 text-gray-900 "
                    >
                      <input
                        disabled={finalized}
                        className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                        id="roundRobinSettings.unratedPlayersToAdvance"
                        type="checkbox"
                        {...register(
                          "roundRobinSettings.unratedPlayersToAdvance"
                        )}
                      />
                      <span className="text-[10px] font-medium text-gray-500">
                        {" "}
                        Allow Unrated Players to Advance
                      </span>
                    </label>
                  </div>
                  <div className="flex flex-row items-start justify-between w-full col-span-4">
                    {/* <label
                  htmlFor="ladderFormat"
                  className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                >
                  <input
                    disabled={finalized}
                    className="p-1 mr-2 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                    id="roundRobinSettings.ladderFormat"
                    type="checkbox"
                    {...register("roundRobinSettings.ladderFormat")}
                  />
                  <span className="text-[10px] font-medium text-gray-500 whitespace-nowrap">
                    {" "}
                    Use Ladder Format
                  </span>
                </label> */}
                    {ladderFormat && (
                      <div className="flex flex-row items-start justify-end w-full ml-5">
                        <label
                          htmlFor="upDown"
                          className="w-full text-sm flex items-center font-medium leading-6 text-gray-900 "
                        >
                          <input
                            disabled={finalized}
                            className="p-0.5 w-10 text-gray-900 bg-transparent border-2 rounded-sm placeholder:text-gray-400 focus:ring-0 text-sm sm:leading-6 "
                            id="roundRobinSettings.upDown"
                            type="number"
                            {...register("roundRobinSettings.upDown")}
                          />
                          <span className="text-[10px] font-medium text-gray-500">
                            {" "}
                            Up/Down
                          </span>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {draw?.id && (
              <div className="">
                <button
                  type="button"
                  onClick={handleDelete}
                  className="mt-5 text-sm px-3 py-2 font-medium bg-red-600 hover:bg-red-700 border shadow rounded text-white "
                >
                  Delete Draw
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddDrawForm;
